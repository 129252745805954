

export const sectionNamesToSectionHeadings = {
    title: "Title",
    research_gap: "Research Gap",
    highlights: "Highlights",
    abstract: "Abstract",
    introduction: "Introduction",
    lit_rev: "Literature Review",
    methodology: "Methodology",
    results: "Results, findings, and discussion",
    conclusion: "Conclusions",
    critical_analysis: "Critical Analysis",
    cover_letter_to_editor: "Cover Letter to Editor",
    reference_list: "References",
    lit_rev_one_by_one: "Literature Review One by One",
    lit_rev_analysis_table: "Literature Review Analysis Table",
    peer_review_for_journal: "Peer Review for Journal",
  }



export const order_for_export = {
    cover_letter_to_editor: "Cover Letter to Editor",
    highlights: "Highlights",
    title: "Title",
    abstract: "Abstract",
    introduction: "Introduction",
    lit_rev: "Literature Review",
    methodology: "Methodology",
    results: "Results",
    conclusion: "Conclusions",
    critical_analysis: "Critical Analysis",
    reference_list: "References",
  }

export const dependencies_for_generating_request = {
  lit_rev: ["lit_rev_one_by_one"],
  introduction: ["lit_rev"],
  research_gap: ["lit_rev_analysis_table"],
  conclusion: ["introduction", "lit_rev"],
  highlights: ["introduction", "conclusion", "lit_rev"],
  title: ["introduction", "lit_rev"],
  cover_letter_to_editor: ["introduction", "conclusion"],
  critical_analysis: ["abstract", "introduction", "lit_rev", "conclusion"],
  abstract: ["introduction", "lit_rev", "conclusion"],
}

export const inputs_by_user = ["research_gap", "methodology", "results"];

export const dependencies_for_generating_article = {
  lit_rev: [...inputs_by_user],
  research_gap: ["lit_rev_analysis_table", ...inputs_by_user],
  introduction: ["lit_rev", ...inputs_by_user],
  conclusion: ["introduction", "lit_rev", ...inputs_by_user],
  highlights: ["introduction", "conclusion", "lit_rev", ...inputs_by_user],
  title: ["introduction", "lit_rev", ...inputs_by_user],
  abstract: ["introduction", "lit_rev", ...inputs_by_user],
  cover_letter_to_editor: ["introduction", "conclusion", "lit_rev", ...inputs_by_user],
  critical_analysis: ["abstract", "introduction", "lit_rev", ...inputs_by_user],
}


export const generateRequest = (
  for_,
  selectedPdfFiles,
  article,
  selectedProjectName
) => {

  if (for_ === "peer_review_for_journal"){
    return {
      article_part: for_,
      file_names: selectedPdfFiles,
      project_name: selectedProjectName,
    };
  }

  const request = {
    article_part: for_,
    file_names: selectedPdfFiles,
    methodology: article.methodology,
    research_gap: article.research_gap,
    results: article.results,
    project_name: selectedProjectName,
  };

  if (["reference_list", "methodology",
      "lit_rev_analysis_table", "lit_rev_one_by_one",
      "results"].includes(for_)){
    return request;
  }

  for (const key in article){
    request[key] = article[key];
  }
  return request;
};

export const tag_for_tabs = {
  lit_ana_tab: ["lit_rev_one_by_one", "lit_rev_analysis_table"],
  pub_prep_tab: ["cover_letter_to_editor", "highlights", "critical_analysis"],
  article_tab: ["research_gap", "title", "abstract", "introduction", "lit_rev", "methodology", "results", "conclusion", "reference_list"],
  peer_review_tab: ["peer_review_for_journal"],
}


export const sections_required_by_user = ["research_gap", "methodology", "results"]


export const isSectionEmpty = (article, sectionName) => {
  return [null, undefined, ""].includes(article[sectionName]);
}