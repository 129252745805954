import ShowToast from "../components/toast";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import "react-chat-elements/dist/main.css";
import { interfaceSlice } from "../store/interfaceSlice";
import { userSlice } from "../store/userSlice";
import "../css/ChatBox.css"; // Import the CSS file

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { projectSlice } from "../store/projectSlice";

import { postWriteArticle } from "../utils/Endpoints";
import { generateRequest, isSectionEmpty } from "../utils/ChatAndArticleFuncs";


export default function SuggestionsForChatBox({
  handleSendMessageForAskingFromDocuments,
  handleSendMessageForComponentModification
}){

  const tickedPdfFiles = useSelector((state) => state.interface.tickedPdfFiles);
  const selectedComponent = useSelector((state) => state.interface.selectedComponent);

  const dispatch = useDispatch();

  const handleClickGeneralSuggestions = (e, suggestion) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(projectSlice.actions.setChatHistoryUserQuery(suggestion));
    dispatch(interfaceSlice.actions.addNEdits());
    dispatch(interfaceSlice.actions.setLlmRespondingToChat(true));
    
    if (selectedComponent === null){
      handleSendMessageForAskingFromDocuments(suggestion);
    } else {
      handleSendMessageForComponentModification(suggestion);
    }
  }

  if (tickedPdfFiles.length === 0 && selectedComponent === null){
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >

      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "bold",
          marginBottom: 1,
        }}
      >
        General Suggestions
      </Typography>

      {/* Suggestions for asking from articles */}
      {selectedComponent === null &&
        suggestions_for_asking_from_articles.map((suggestion, index) => (
          <Suggestion
            key={index + "-suggestion"}
            suggestion={suggestion}
            handleClick={handleClickGeneralSuggestions}
          />
        ))
      }

      {selectedComponent !== null &&
        suggestions_for_modifying_sections.map((suggestion, index) => (
          <Suggestion
            key={index + "-suggestion"}
            suggestion={suggestion}
            handleClick={handleClickGeneralSuggestions}
          />
        ))
      }

      {/* A divider */}
      <Divider sx={{
        marginTop: 1,
        marginBottom: 1,
      }}/>

      {["methodology", "results", "research_gap"].includes(selectedComponent) &&
        <>

          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            Advanced Suggestions
          </Typography>

          <ResearchGapAdvancedSuggestions />
          <MethodologyAdvancedSuggestions />
          <ResultsAdvancedSuggestions />

          <Divider sx={{
            marginTop: 1,
            marginBottom: 1,
          }}/>
        </>
      }

      

    </Box>
  )
}



function Suggestion({
  suggestion,
  handleClick,
  advanced = false
}){

  const selectedComponent = useSelector((state) => state.interface.selectedComponent);

  let height = "35px";
  if (['results', 'methodology'].includes(selectedComponent) && advanced){
    height = "50px";
  }

  return (
    <Button
      onClick={(e) => handleClick(e, suggestion)}
      variant="outlined"
      sx={{
        borderRadius: "20px",
        textTransform: "none",
        color: "#909090",
        borderColor: "#909090",
        marginBottom: 1,
        height: height,
      }}
    >
      <ArrowForwardIosIcon
        sx={{
          fontSize: "12px",
          marginRight: 1
        }}
      />
      <Typography
        sx={{
          fontSize: "14px",
          textAlign: "left"
        }}
      >
        {suggestion}
      </Typography>
    </Button>
  )
}




const suggestions_for_asking_from_articles = [
  "What are the research gaps in these articles?",
  "What are the main findings of these articles?",
  "What are the main conclusions of these articles?",
  "What are the main recommendations of these articles?",
  "What are the main limitations of these articles?",
  "What are the main strengths of these articles?",
]


const suggestions_for_modifying_sections = [
  "Can you expand and add more details to this section?",
  "Can you rephrase this section?",
  "Can you add more details to this section?",
  "Can you add more examples to this section?",
  "Add subheadings to enhance the organization",
  "Ensure consistency in terminology",
]

function ResearchGapAdvancedSuggestions({}){

  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const selectedPdfFiles = useSelector((state) => state.interface.selectedPdfFiles);
  const article = useSelector((state) => state.project.article);
  const selectedProjectName = useSelector((state) => state.user.selectedProjectName);

  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const dispatch = useDispatch();

  const handleClickGenerateResearchGap = async (e, suggestion) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(interfaceSlice.actions.setLlmRespondingToChat(true));
    dispatch(interfaceSlice.actions.setLlmResponseLoading(true));
    const request = generateRequest(
      "research_gap",
      selectedPdfFiles,
      article,
      selectedProjectName
    )

    const result = await postWriteArticle(request, user, accessToken);

    if (result === "Not enough credits"){
      ShowToast("You do not have enough credits to generate the research gap.", "error");
      return
    }

    if (result === undefined){
      ShowToast("There was an error generating the research gap.", "error");
      return
    }

    dispatch(projectSlice.actions.addToChatHistoryWithComponents({
      sectionName: "research_gap",
      chatContent: {
        position: "left",
        type: "text",
        text: result,
        date: new Date().toISOString(),
      }
    }));

    dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
    dispatch(interfaceSlice.actions.setLlmResponseLoading(false));
    dispatch(interfaceSlice.actions.addNEdits());
    dispatch(interfaceSlice.actions.setRefetchGetCredit());
  }

  return (
    <>
      {selectedComponent==="research_gap" &&
        <Suggestion
          suggestion="Identify the future research directions based on the literature"
          handleClick={handleClickGenerateResearchGap}
          advanced={true}
        />
      }
    </>
  )

}


function MethodologyAdvancedSuggestions({}){

  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const selectedPdfFiles = useSelector((state) => state.interface.selectedPdfFiles);
  const article = useSelector((state) => state.project.article);
  const selectedProjectName = useSelector((state) => state.user.selectedProjectName);

  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const dispatch = useDispatch();

  const handleClickGenerateMethodology = async (e, suggestion) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSectionEmpty(article, "research_gap")){
      ShowToast("You need to have a research gap before generating the methodology.", "error");
      return;
    }

    dispatch(interfaceSlice.actions.setLlmRespondingToChat(true));
    const request = generateRequest(
      "methodology",
      selectedPdfFiles,
      article,
      selectedProjectName
    )

    const result = await postWriteArticle(request, user, accessToken);

    if (result === "Not enough credits"){
      ShowToast("You do not have enough credits to generate the methodology.", "error");
      return
    }

    if (result === undefined){
      ShowToast("There was an error generating the methodology.", "error");
      return
    }

    dispatch(projectSlice.actions.addToChatHistoryWithComponents({
      sectionName: "methodology",
      chatContent: {
        position: "left",
        type: "text",
        text: result,
        date: new Date().toISOString(),
      }
    }));

    dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
    dispatch(interfaceSlice.actions.setLlmResponseLoading(false));
    dispatch(interfaceSlice.actions.addNEdits());
    dispatch(interfaceSlice.actions.setRefetchGetCredit());
  }

  return (
    <>
      {selectedComponent==="methodology" &&
        <Suggestion
          suggestion="Draft a methodology section based on the research gaps. 
            You can add more details to the methodology as a user query"
          handleClick={handleClickGenerateMethodology}
          advanced={true}
        />
      }
    </>
  )
}


function ResultsAdvancedSuggestions({}){

  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const selectedPdfFiles = useSelector((state) => state.interface.selectedPdfFiles);
  const article = useSelector((state) => state.project.article);
  const selectedProjectName = useSelector((state) => state.user.selectedProjectName);

  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const dispatch = useDispatch();

  const handleClickGenerateResults = async (e, suggestion) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSectionEmpty(article, "research_gap")){
      ShowToast("You need to have a research gap before generating the results.", "error");
      return;
    }

    dispatch(interfaceSlice.actions.setLlmRespondingToChat(true));
    const request = generateRequest(
      "results",
      selectedPdfFiles,
      article,
      selectedProjectName
    )

    const result = await postWriteArticle(request, user, accessToken);

    if (result === "Not enough credits"){
      ShowToast("You do not have enough credits to generate the results.", "error");
      return
    }

    if (result === undefined){
      ShowToast("There was an error generating the results.", "error");
      return
    }

    dispatch(projectSlice.actions.addToChatHistoryWithComponents({
      sectionName: "results",
      chatContent: {
        position: "left",
        type: "text",
        text: result,
        date: new Date().toISOString(),
      }
    }));

    dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
    dispatch(interfaceSlice.actions.addNEdits());
  }

  return (
    <>
      {selectedComponent==="results" &&
        <Suggestion
          suggestion="Draft a results section based on the research gaps and methodology.
            You can add more details for better outcomes"
          handleClick={handleClickGenerateResults}
          advanced={true}
        />
      }
    </>
  )
} 
