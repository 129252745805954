

import { Box, Typography } from '@mui/material';

export default function Post3_GetIdeasHowToConductYourResearch() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>

        Sometimes, you may not know how to conduct your research. <br/>
        You may not know which method to use or how to analyze the data. <br/>
        You may not know how to interpret the results. <br/>
        OR <br/>
        You may have a very similar research method as in the previous studies, but a different research question. <br/><br/>

        In this case, you can use PaperGPT to get ideas how to conduct your research. <br/>

        <b>Step 1:</b> Upload the papers you have read. <br/>
        <b>Step 2:</b> Click on the "Methodology" section. <br/>
        <b>Step 3:</b> Click on the "Draft a methodology..." button. <br/><br/>

        And that's it! You will get a scientific methodology tailored to your research question in seconds.


        <br/><br/>

        <b>Dependencies</b>
        <ul>
          <li>Research gap</li>
        </ul>

        <b>Optional</b>
        <ul>
          <li>A query in the methodology section</li>
        </ul>

        <b>Note:</b> Methodology is written based on the research gap and the literature you have read.
        The more detailed information you provide, the better the methodology will be.

      </Typography>

      <img
        src={"/posts/post3/image1.png"}
        alt="How to Get Ideas How to Conduct Your Research"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h6" gutterBottom>
        The chatbox allows you to modify the sections' content. Simply ask a question or use 
        a predefined prompt.
      </Typography>

      <img
        src={"/posts/post3/image2.png"}
        alt="How to Modify Sections"
        style={{
          width: "100%",
        }}
      />

      
      <br/><br/><br/><br/>

      <Typography variant="h6" gutterBottom>
        You can also highlight a piece of text and ask PaperGPT to explain it, rewrite it, or improve it. <br/><br/>
        This is a great way to improve the quality of your paper.
      </Typography>

      <img
        src={"/posts/post3/image3.png"}
        alt="How to Modify Sections"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h6" gutterBottom>
        The rewritten text is shown in bold in the chatbox. <br/>
        You can directly copy it to your paper by clicking on the "Copy" button or "Assign" to the section.
      </Typography>

      <img
        src={"/posts/post3/image4.png"}
        alt="How to Assign to Section"
        style={{
          width: "100%",
        }}
      />

    </Box>
  );
}