import React, { useEffect, useState, useRef, useMemo } from "react";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import { useTable } from 'react-table';
import {
  Box,
  TextField,
  Button,
  Grid,
  Tooltip
} from "@mui/material";


import ChatIcon from '@mui/icons-material/Chat';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import BackspaceIcon from '@mui/icons-material/Backspace';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { keyframes } from '@emotion/react';

import { useDispatch, useSelector } from "react-redux";

import { postWriteArticle, fetchModifyComponents } from "../utils/Endpoints";

import {
  sectionNamesToSectionHeadings,
  order_for_export,
  dependencies_for_generating_article,
  tag_for_tabs,
  sections_required_by_user,
  isSectionEmpty,
  generateRequest,
} from "../utils/ChatAndArticleFuncs";

import { interfaceSlice } from "../store/interfaceSlice";
import { userSlice } from "../store/userSlice";
import { projectSlice } from "../store/projectSlice";

import ShowToast from "./toast";


export default function ArticleComponent ({}) {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const selectedTag = useSelector((state) => state.interface.selectedTag);
  const selectedTab = useSelector((state) => state.interface.selectedTab);
  const whichSectionGenerating = useSelector((state) => state.interface.whichSectionGenerating);

  const [articleComponentSize, setArticleComponentSize] = useState(6);

  const selectedPdfFiles = useSelector((state) => state.interface.selectedPdfFiles);
  const tickedPdfFiles = useSelector((state) => state.interface.tickedPdfFiles);
  const llmResponseLoading = useSelector((state) => state.interface.llmResponseLoading);

  const selectedProjectName = useSelector((state) => state.user.selectedProjectName);
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const llmRespondingToChat = useSelector((state) => state.interface.llmRespondingToChat);


  const article = useSelector((state) => state.project.article);
  const chatHistory = useSelector((state) => state.project.chatHistory);
  const projectKey = useSelector((state) => state.project.projectKey);
  const chatKey = useSelector((state) => state.project.chatKey);


  // ---------------------------------------------------------------

  useEffect(() => {

    const fetchArticle = async () => {

      if (whichSectionGenerating !== null) {

        dispatch(interfaceSlice.actions.setLlmResponseLoading(true));

        const request = generateRequest(
          whichSectionGenerating,
          (['peer_review_for_journal'].includes(whichSectionGenerating)) ? tickedPdfFiles : selectedPdfFiles,
          article,
          selectedProjectName
        );

        if (isSectionEmpty(article, whichSectionGenerating)) {
  
          const result = await writeArticle(request);
  
          if (result !== null) {
            dispatch(projectSlice.actions.setArticle({
              sectionName: whichSectionGenerating,
              sectionContent: result,
            }));
            setNextSectionToGenerate(whichSectionGenerating, dispatch);
            dispatch(interfaceSlice.actions.addNEdits());
          }
        }

      }

      dispatch(interfaceSlice.actions.setLlmResponseLoading(false));
      dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
      dispatch(interfaceSlice.actions.setRefetchGetCredit());
    }
    fetchArticle();
    
  }, [whichSectionGenerating]);


  useEffect(() => {
    dispatch(interfaceSlice.actions.setLlmResponseLoading(false));
    dispatch(interfaceSlice.actions.setWhichSectionGenerating(null));
  }, [selectedProjectName]);


  useEffect(() => {
    if (selectedTag === "lit_rev_analysis_table_tag") {
      setArticleComponentSize(9);
    } else {
      setArticleComponentSize(6);
    }
  }, [selectedTag, selectedTab])


  ///////////////////////////////////////////////////////////////////
  ///////////////////// functions ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  const writeArticle = async (request) => {
    const result = await postWriteArticle(request, user, accessToken);

    if (result === "Not enough credits. Please upgrade."){
      ShowToast("Not enough credits. Please upgrade.");
      dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
      dispatch(interfaceSlice.actions.setLlmResponseLoading(false));
      return null;
    }
    return result;
  };


  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      // Make selectedComponent null
      dispatch(interfaceSlice.actions.setSelectedComponent(null));
    }
  }

  const handleTabChange = (event, newValue) => {
    dispatch(interfaceSlice.actions.setSelectedTab(newValue));
    dispatch(interfaceSlice.actions.setSelectedTag("all_tag"));
  };

  if (!article || !projectKey || !chatKey){
    return null;
  }

  return (
    <Grid item xs={articleComponentSize}>
    <Box
      sx={{
        height: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        borderRadius: 1,
        marginTop: 1,
        marginLeft: 1,
        marginRight: 1,
        border: "1px solid #ccc",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        pointerEvents: llmRespondingToChat || llmResponseLoading ? "none" : "auto",
      }}
    >

      {/* Tabs: Create 3 tabs: Literature Analysis, Article, and Settings */}
      <Box
        sx={{
          width: "100%",
          borderBottom: "1px solid #ccc",
          marginBottom: 1,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            "& .Mui-selected": {
              color: "black",
            },
            "& .Mui-selected:hover": {
              color: "black",
            },
            height: "10px",
            "& .MuiTab-root": {
              textTransform: "none",
            },
          }}
        >
          <Tab
            label="Literature Analysis"
            value="lit_ana_tab"
            sx={{fontSize: "13px"}}
          />
          <Tab
            label="Article"
            value="article_tab"
            sx={{fontSize: "13px"}}
          />
          <Tab
            label="Pre-Publication"
            value="pub_prep_tab"
            sx={{fontSize: "13px"}}
          />
          <Tab
            label="Peer Review"
            value="peer_review_tab"
            sx={{fontSize: "13px"}}
          />
        </Tabs>

      </Box>
      

      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden", // Prevent horizontal overflow
          marginBottom: 1,
        }}
      >

        <TagsSection/>

        {/* Iterate over the sectionNamesToSectionHeadings */}
        {Object.keys(sectionNamesToSectionHeadings).map((sectionName) => {
          return (
            <PaperSectionsInputByUser
              key={sectionName+"-MainSectionInputByUser"}
              sectionName={sectionName}
              handleKeyDown={handleKeyDown}
            />
          );
        })}

      </Box>

      <ArticleButtons
        article={article}
        projectKey={projectKey}
      />

    </Box>
    </Grid>
  );
}


// Some useful snippets for the above code:

function PaperSectionsInputByUser({
  sectionName,
  handleKeyDown,
}) {

  const dispatch = useDispatch();

  const [lastItemClicked, setLastItemClicked] = useState(null);

  const whichSectionGenerating = useSelector((state) => state.interface.whichSectionGenerating);
  const llmResponseLoading = useSelector((state) => state.interface.llmResponseLoading);
  const llmRespondingToChat = useSelector((state) => state.interface.llmRespondingToChat);
  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const nEdits = useSelector((state) => state.interface.nEdits);
  const selectedPdfFiles = useSelector((state) => state.interface.selectedPdfFiles);
  const tickedPdfFiles = useSelector((state) => state.interface.tickedPdfFiles);

  const selectedTab = useSelector((state) => state.interface.selectedTab);
  const selectedTag = useSelector((state) => state.interface.selectedTag);

  const article = useSelector((state) => state.project.article);
  const projectKey = useSelector((state) => state.project.projectKey);
  const chatKey = useSelector((state) => state.project.chatKey);
  const chatHistory = useSelector((state) => state.project.chatHistory);

  
  const handleClick = (e) =>{
    e.preventDefault();
    e.stopPropagation();

    // If clicked on the textfield component
    if (e.target.id === sectionName+"-inputByUser" &&
      selectedComponent === sectionName){
      return
    }

    dispatch(interfaceSlice.actions.setSelectedComponent(sectionName));
  }

  const handleMouseUp = (e) => {


    const clickedComponent = e.target.id.split("-")[0];
    const selection = window.getSelection();
    const highlightedText = selection.toString().trim();

    if (highlightedText === ""){
      dispatch(interfaceSlice.actions.setHighlightedText({
        "text": "",
        "sectionName": clickedComponent,
      }));
    }

    if (highlightedText && highlightedText !== "") {
      dispatch(interfaceSlice.actions.setHighlightedText({
        "text": highlightedText,
        "sectionName": clickedComponent,
      }));
    }
  };

  if (localStorage.getItem(projectKey) !== null) {
      
    if (Object.keys(article).length === 0){
      article = JSON.parse(localStorage.getItem(projectKey))
    }
  }

  const [value, setValue] = useState(() => {
    return article[sectionName];
  });

  const handleInputChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(event.target.value);

    if (event.key === "Enter" || event.key === "Escape"){
      event.target.blur();
    }
  };

  const handleReset = (e) => {
    dispatch(projectSlice.actions.setArticle({
      sectionName: sectionName,
      sectionContent: "",
    }));
    setValue("");
    e.preventDefault();
    e.stopPropagation();
  }

  
  const handleGenerate = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedComponent === "peer_review_for_journal" && tickedPdfFiles.length !== 1){
      ShowToast("Please select only one pdf file for generating peer review.");
      return;
    }

    dispatch(interfaceSlice.actions.setLlmResponseLoading(true));
    dispatch(interfaceSlice.actions.setWhichSectionGenerating(sectionName));
  }

  // Get the request and check if any of the values are undefined, null, or empty strings.
  // If so, show a toast and return.
  const isReady = (()=>{


    if (["lit_rev_one_by_one", "lit_rev_analysis_table", "peer_review_for_journal"].includes(sectionName)){
      return true;
    }

    if (sections_required_by_user.includes(sectionName)){
      return true;
    }

    if (sectionName === "reference_list"){
      if (selectedPdfFiles.length === 0){
        return false;
      } 
      return true;
    }

    // Check if all the dependencies are met
    for (const dependency of dependencies_for_generating_article[sectionName]){
        if (isSectionEmpty(article, dependency)){
          if (sectionName === "lit_rev" || sectionName === "peer_review_for_journal"){
          }
          return false;
        }
      }
      return true;
    })();

  const titleOfGenerateButton = (()=>{
    if (isReady){
      return "Generate " + sectionNamesToSectionHeadings[sectionName];
      }

    
    if (sectionName === "reference_list" || sections_required_by_user.includes(sectionName)){
      return
    }

    var dependency_message = "";
    for (const dependency of dependencies_for_generating_article[sectionName]){
      if (isSectionEmpty(article, dependency)){
        dependency_message += " | " + sectionNamesToSectionHeadings[dependency];
      }
    }

    dependency_message += " | "
    return sectionNamesToSectionHeadings[sectionName] + " needs " + dependency_message + " before it can be generated.";
  })();
  

  // --------------------------------------------------------------------------
  //               Use Effects
  // --------------------------------------------------------------------------
  useEffect(() => {
    dispatch(projectSlice.actions.setArticle({
      sectionName: sectionName,
      sectionContent: value,
    }));
  }, [value]);

  useEffect(() => {
    setValue(article[sectionName]);
  }, [nEdits]);

  // --------------------------------------------------------------------------
  //               Render
  // --------------------------------------------------------------------------

  // If the section is not in the selected tab, don't render it
  if (!tag_for_tabs[selectedTab].includes(sectionName)){
    return null;
  }

  // If the section is not in the selected tag, don't render it
  if (selectedTag !== "all_tag" && selectedTag !== sectionName + "_tag"){
    return null;
  }

  const getPlaceHolder = ()=>{
    if (sections_required_by_user.includes(sectionName)){
      return "Please enter the " + sectionNamesToSectionHeadings[sectionName] + " here."  + 
      "\nYou can use 'Advanced Suggestions' in the ChatBox to ask PaperGPT for help.";
    }

    let placeholder = "Keep empty if you want AI to generate the content."

    // Check if all the dependencies are met
    let all_dependencies_met = true;
    if (dependencies_for_generating_article[sectionName] !== undefined){
      for (const dependency of dependencies_for_generating_article[sectionName]){
        if (isSectionEmpty(article, dependency)){
          all_dependencies_met = false;
        }
      }
    }

    if (!all_dependencies_met){
      placeholder += "\n\nRequirements: \n";
      for (const dependency of dependencies_for_generating_article[sectionName]){
        if (isSectionEmpty(article, dependency)){
          placeholder += sectionNamesToSectionHeadings[dependency] + " | ";
        }
      }
      // Remove the last " | "
      placeholder = placeholder.slice(0, -3);
    } else {
      placeholder += "\n\nReady for generation!";
    }
    
    return placeholder;

  };


  return (
    <Box
      margin={1}
      sx={{
        "&:hover": 
          {
            boxShadow: 3,
          },
        cursor: "pointer",
        boxShadow: (selectedComponent === sectionName) ? 3 : 0,
        outline: 'none', // Remove the default focus outline
        '&:focus': {
          outline: 'none', // Ensure outline is removed on focus
        },
        pointerEvents: (llmResponseLoading || llmRespondingToChat) ? "none" : "auto", // Prevent interaction
      }}
      borderRadius={1}
      padding={1}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex="0"
      border={0}
    >

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          borderRadius: 1,
        }}
      >
        {/* Heading Text */}
        <Typography
          variant="h6"
          textAlign="left"
          width="100%"
          marginX={1}
          borderRadius={1}
        >
          {sections_required_by_user.includes(sectionName) && (
            <Typography
              marginRight={1}
              color="red"
              fontSize={20}
              component="span"
            >
              *
            </Typography>
          )}
          {sectionNamesToSectionHeadings[sectionName]}

        </Typography>

        

        {/* Reset Button */}
        {selectedComponent === sectionName &&(
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >

            <Tooltip
              title={"Clear " + sectionNamesToSectionHeadings[sectionName]}
            >
              <Button
                sx={{
                  minWidth: '0', // Prevent minimum width
                  marginRight: 1,
                  color: '#909090',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    transform: 'scale(1.1)'
                  }
                }}
                disabled={llmResponseLoading || llmRespondingToChat}
                onClick={handleReset}
                id={sectionName + "-reset-chatWithHighlight"}
              >
                <BackspaceIcon
                  id={sectionName + "-reset-icon-chatWithHighlight"}
                  sx={{
                    fontSize: "16px",
                  }}
                />
              </Button>
            </Tooltip>

          </Box>
        )}

        {/* Generate Button */}
        {!sections_required_by_user.includes(sectionName) &&
          !llmResponseLoading && !llmRespondingToChat && isReady &&
          isSectionEmpty(article, sectionName) &&
          selectedPdfFiles.length > 0 &&
          // selectedComponent === sectionName &&
          
          (
          <Tooltip title={titleOfGenerateButton}>
            <Button
                  sx={{
                    minWidth: '0', // Prevent minimum width
                    color: "#909090",
                    marginRight: 1,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      transform: 'scale(1.1)'
                    }
                  }}
                  disabled={!isReady }
                  onClick={handleGenerate}
                >
                  <PlayArrowIcon
                    id={sectionName + "-generate-icon-chatWithHighlight"}
                    sx={{
                      fontSize: "22px",
                      color: "DodgerBlue",
                    }}
                    />
                </Button>
              </Tooltip>
        )}
        
        {/* Chat Icon */}
        {chatHistory['withComponents'][sectionName] !== undefined &&
            chatHistory['withComponents'][sectionName] !== null &&
            <ChatIcon
              sx={{
                color: "lightgray",
                marginRight: 1,
                height: "15px",
              }}
            />
        }

        {/* Selected Component Icon */}
        {selectedComponent !== null && 
        <RadioButtonCheckedIcon
          sx={{
            "color": selectedComponent === sectionName? "#696969": "#E8E8E8",
            fontSize: "16px"
          }}
          >
        </RadioButtonCheckedIcon>
        }
      </Box>

      {/* Text Field */}

      {sectionName !== "lit_rev_analysis_table" && (
        <Box
          sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderBottomLeftRadius: 1,
          borderBottomRightRadius: 1,
        }}
        id={sectionName+"-inputByUser"}
        onClick={(e) => e.stopPropagation()}
      >

        <FormControl fullWidth
          sx={{
            marginBottom: 0,
            fontSize: 11,
          }}>

          <Tooltip arrow>
            <TextField
              id={sectionName+"-inputByUser"}
              placeholder={getPlaceHolder()}
              multiline
              fullWidth
              value={value}
              onChange={handleInputChange}
              onClick={handleClick}
              onMouseUp={handleMouseUp}
              variant="outlined"
              sx={{
                marginRight: 2,
                '& .MuiOutlinedInput-root': {
                  border: 'none', // Remove the border
                  '& fieldset': {
                    border: 'none', // Remove the fieldset border
                  },
                  '&:hover fieldset': {
                    border: 'none', // Remove border on hover
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none', // Remove border when focused
                  },
                },
                '& .MuiInputBase-input': {
                  fontSize: '13px',
                },
              }}
              onKeyDown={handleKeyDown}
            />
          </Tooltip>

          </FormControl>
      </Box>
      )}

      {sectionName === "lit_rev_analysis_table" &&
        !isSectionEmpty(article, "lit_rev_analysis_table") && (
          <LiteratureAnalysisTable />
      )}
          
      
      

    </Box>
  
  )
}

function ArticleButtons ({
    article,
    projectKey,
  }) {

  const dispatch = useDispatch();
  const llmResponseLoading = useSelector((state) => state.interface.llmResponseLoading);
  const clearLocalStorage = () => {

    dispatch(projectSlice.actions.resetArticle());
    dispatch(projectSlice.actions.resetChatHistory());
    dispatch(interfaceSlice.actions.addNEdits());

  }


  const downloadArticle = () => {
    const element = document.createElement("a");
    var text = ""

    // Iterate over the keys and values, if "message" in the value, then make it null
    for (const key in order_for_export) {
      let value = order_for_export[key];
      if (article[key] !== null) {
        text += `# ${value}\n\n${article[key]}\n\n`;
      } else {
        text += `# ${value}\n Not generated \n\n\n\n`;
      }
    }

    // Create a Blob with the text content and specify the MIME type for a Word document
    const file = new Blob([text], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });

    element.href = URL.createObjectURL(file);
    element.download = "article.docx"; // Set the filename to have a .docx extension
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center", // Added this line to center the button
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
        alignContent: "center",
        height: "5%",
      }}
    >
      
      <Tooltip title="Download the article">
        <Button
          disabled={llmResponseLoading}
          sx={{
            width: "20%",
            height: "100%",
            marginTop: 0,
            marginRight: 1,
            color: "#909090",
            '&:hover': {
              color: "#909090",
              backgroundColor: "#fff",
              transform: 'scale(1.1)'
            },
          }}
          onClick={() => downloadArticle()}
          >
          <DownloadIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Restart Everything">
        <Button
          disabled={llmResponseLoading}
          sx={{
            width: "20%",
            height: "100%",
            marginTop: 0,
            marginRight: 1,
            color: "#909090",
            '&:hover': {
              color: "#909090",
              transform: 'scale(1.1)',
              backgroundColor: "#fff",
            },
          }}
          onClick={() => clearLocalStorage()}
          >
          <ReplayIcon />
        </Button>
      </Tooltip>
      
      {/* {(
        <Button
          onClick={() => handleClickWriteOrStop()}
          sx={{
            width: "20%",
            height: "100%",
            marginTop: 0,
            color: llmResponseLoading ? "red" : "#909090",
            '&:hover': {
              color: llmResponseLoading ? "red" : "#909090",
              transform: 'scale(1.1)',
              backgroundColor: "#fff",
            },
          }}
        >
          {llmResponseLoading ? (
            <Tooltip title="Stop">
              <StopIcon
                sx={{
                  color: "red"
                }}
              >
              </StopIcon>
            </Tooltip>
          ) : (

            <Tooltip title="Generate the article">
              <ModeEditOutlineIcon />
            </Tooltip>
          )}
        </Button>
      )} */}
    </Box>
  )
}




function TagsSection() {

  const dispatch = useDispatch();

  const selectedTag = useSelector((state) => state.interface.selectedTag);
  const selectedTab = useSelector((state) => state.interface.selectedTab);

  const handleSetSelectedTag = (tag) => {
    dispatch(interfaceSlice.actions.setSelectedTag(tag));
  }

  return (
    <Box
      sx={{
        width: "95%",
        paddingLeft: 3,
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
      }}
    >
      

      <TagItem tagName="all_tag" tag="All" />

      {tag_for_tabs[selectedTab].map((tag) => {
        return (
          <TagItem
            key={tag + "_tag"}
            tagName={tag + "_tag"}
            tag={sectionNamesToSectionHeadings[tag]}
          />
        )
      })}

      
    </Box>
  );
}

function TagItem({tagName, tag}) {

  const dispatch = useDispatch();
  const selectedTag = useSelector((state) => state.interface.selectedTag);

  const selected = (selectedTag === tagName) ? true : false;


  const handleClick = () => {
    const sectionName = tagName.replace("_tag", "")

    if (selectedTag === tagName) {
      return;
    }

    if (tagName === "all_tag") {
      dispatch(interfaceSlice.actions.setSelectedComponent(null));
    } else {
      dispatch(interfaceSlice.actions.setSelectedComponent(sectionName));
    }
    dispatch(interfaceSlice.actions.setSelectedTag(tagName));
  }

  return (
    <Button
        onClick={handleClick}
        sx={{
          height: "20px",
          border: selected ? "1px solid black": "1px solid #DCDCDC",
          borderRadius: 5,
          color: selected? "green": "	#DCDCDC",
          marginRight: 1,
          marginBottom: 1,
          textTransform: "lowercase",
          boxShadow: selected ? 3 : 0,
          '&:hover': {
            backgroundColor: "#fff",
            color: selected ? "green": "#909090",
            border: "1px solid #909090",
            boxShadow: 3,
          },
        }}
      >
        {tag}
    </Button>
  )

}


const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 10px #FF0000;
  }
  50% {
    box-shadow: 0 0 20px #FF0000;
  }
  100% {
    box-shadow: 0 0 10px #FF0000;
  }
`;



function LiteratureAnalysisTable({}){

  const article = useSelector((state) => state.project.article);

  const data = useMemo(() => {
    const parsedData = [];
    if (!isSectionEmpty(article, "lit_rev_analysis_table")) {
      for (const [key, value] of Object.entries(JSON.parse(article['lit_rev_analysis_table']))) {
        parsedData.push({
          title: key.slice(0, 20),
          "Research Gap": value["Research Gap"],
          "Methodology": value["Methodology"],
          "Findings": value["Findings"],
          "Practical Implications": value["Practical Implications"],
          "Future Work": value["Future Work"],
          "Data": value["Data"],
          "Conclusion": value["Conclusion"]
        });
      }
    }
    return parsedData;
  }, [article["lit_rev_analysis_table"]]);

  const columns = useMemo(() => [
    { Header: "Title", accessor: "title" },
    { Header: "Research Gap", accessor: "Research Gap" },
    { Header: "Methodology", accessor: "Methodology" },
    { Header: "Findings", accessor: "Findings" },
    { Header: "Practical Implications", accessor: "Practical Implications" },
    { Header: "Future Work", accessor: "Future Work" },
    { Header: "Data", accessor: "Data" },
    { Header: "Conclusion", accessor: "Conclusion" }
  ], []);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  const csvData = useMemo(() => {
    const data = rows.map((row) => {
      const rowData = {};
      columns.forEach((column) => {
        rowData[column.Header] = row.values[column.accessor];
      });
      return rowData;
    });
    return data;
  }, [columns, rows]);

  const downloadCSV = () => {
    // Create CSV headers
    const headers = columns.map(column => column.Header);
    
    const csvRows = [
      headers.join(','), // Header row
      ...csvData.map(row => 
        headers.map(header => {
          const cellData = row[header] || '';
          return `"${cellData.toString().replace(/"/g, '""')}"`;
        }).join(',')
      )
    ];

    // Create blob and download
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', 'literature_analysis.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Box>
      <table {...getTableProps()} style={{ fontSize: '10px', width: '100%' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id + "lit_rev_analysis_table_header_row"}
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{ borderBottom: '1px solid #ccc', padding: '8px' }}
                  key={column.id + "lit_rev_analysis_table_header_cell"}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: '1px solid #ccc' }} key={row.id + "lit_rev_analysis_table_row"}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} style={{ padding: '8px' }} key={cell.column.id + cell.row.id +"lit_rev_analysis_table_cell"}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box mt={2}>
        <Button 
          onClick={downloadCSV}
          sx={{
            fontSize: 12,
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
            textTransform: 'none',
          }}
        >
          Download
        </Button>
      </Box>
    </Box>
  )
}



function setNextSectionToGenerate(whichSectionGenerating, dispatch){

  if (whichSectionGenerating === "lit_rev_one_by_one"){
    dispatch(interfaceSlice.actions.setWhichSectionGenerating("lit_rev"));
  } else {
    dispatch(interfaceSlice.actions.setWhichSectionGenerating(null));
  }
}

