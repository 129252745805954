import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../store/userSlice";
import { interfaceSlice } from "../store/interfaceSlice";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { handleDeletePdf } from "../utils/Endpoints";

import axios from "axios";
import ShowToast from "../components/toast";
import pdfToText from "react-pdftotext";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// Set the workerSrc property
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

export default function LiteratureComponent({}) {
  
  const fileUploadRef = useRef(null);
  const llmResponseLoading = useSelector(
    (state) => state.interface.llmResponseLoading
  );
  const llmRespondingToChat = useSelector((state) => state.interface.llmRespondingToChat);
  const uploadingFiles = useSelector((state) => state.interface.uploadingFiles);
  const deletingFiles = useSelector((state) => state.interface.deletingFiles);

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );


  const isPaidUser = useSelector((state) => state.user.isPaidUser);

  const maximumPdfFiles = isPaidUser ? 100 : 2;


  const nEdits = useSelector((state) => state.interface.nEdits);

  const selectedPdfFiles = useSelector(
    (state) => state.interface.selectedPdfFiles
  );

  const refetch = useSelector((state) => state.interface.refetchGetAllPdfFiles);
  const refetchGetAllProjects = useSelector(
    (state) => state.interface.refetchGetAllProjects
  );
  
  const tickedPdfFiles = useSelector((state) => state.interface.tickedPdfFiles);
  const [pdfFilesToDelete, setPdfFilesToDelete] = useState([]);
  const [pdfFilesToUpload, setPdfFilesToUpload] = useState([]);


  //////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// useEffects ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(interfaceSlice.actions.setRefetchGetAllPdfFiles());
  }, [selectedProjectName]);

  useEffect(() => {

    if (pdfFilesToDelete.length === 0) {
      return;
    }

    async function deleteFiles() {
      if (pdfFilesToDelete.length > 0) {
        // Disable refetch during deletion process
        const deletePromises = tickedPdfFiles.map(fileName => 
          handleDeletePdf(
            dispatch,
            user,
            accessToken,
            fileName,
            selectedPdfFiles,
            interfaceSlice.actions.setSelectedPdfFiles,
            selectedProjectName,
            false // Don't refetch for each deletion
          )
        );
        
        // Wait for all deletions to complete
        await Promise.all(deletePromises);

        // Reset deleting state
        dispatch(interfaceSlice.actions.setDeletingFiles(false)); // Ensure this is set to false after deletion

        // Trigger single refetch after all deletions
        dispatch(interfaceSlice.actions.setRefetchGetAllPdfFiles());

        // Remove the files to delete from the tickedPdfFiles
        dispatch(interfaceSlice.actions.setTickedPdfFiles(tickedPdfFiles.filter(file => !pdfFilesToDelete.includes(file))));

        // Dispatch the pdfFilesToDelete to be empty
        setPdfFilesToDelete([]);
      }
    }
    deleteFiles();
  }, [pdfFilesToDelete]);


  useEffect(() => {
    if (pdfFilesToUpload.length === 0) {
      return;
    }

    async function uploadFiles() {
      if (pdfFilesToUpload.length > 0) {
        try {
          await handleUpload(pdfFilesToUpload);
        } catch (error) {
          console.error("Error during file upload:", error);
        } finally {
          dispatch(interfaceSlice.actions.setUploadingFiles(false));
          setPdfFilesToUpload([]);
          dispatch(interfaceSlice.actions.setRefetchGetAllPdfFiles());
        }
      }
    }
    uploadFiles();

  }, [pdfFilesToUpload]);

  //////////////////////////////////////////////////////////////////////////////
  /////////////////////////////// functions ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const handleFileChange = async (event) => {
    var files = Array.from(event.target.files);

    // Check if all selected files are PDFs
    const areAllPDFs = files.every((file) => file.type === "application/pdf");

    if (!areAllPDFs) {
      ShowToast("Only pdf files are supported at this moment.");
      return;
    }

    if (!files.length) {
      console.log("No files selected");
      return;
    }

    dispatch(interfaceSlice.actions.setUploadingFiles(true));

    // Slicing the files to a maximum of 20 - to avoid overloading the server
    const max_files = Math.max(maximumPdfFiles - selectedPdfFiles.length, 0);
    if (files.length > max_files) {
      ShowToast(`You can only upload up to ${maximumPdfFiles} files at a time.
            I'm only processing the first ${max_files} files.`);
      files = files.slice(0, max_files);
    }

    // Validate each file
    var validFiles = files.filter((file) => {
      if (!file.type || file.type !== "application/pdf") {
        console.warn(`File "${file.name}" is not a valid PDF`);
        return false;
      }
      if (file.size === 0) {
        console.warn(`File "${file.name}" is empty`);
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) {
      console.error("No valid PDF files to upload");
      dispatch(interfaceSlice.actions.setUploadingFiles(false));
      return;
    }

    // Convert PDFs to text Files
    const textFiles = await Promise.all(
      validFiles.map(async (file) => {
        try {
          const text = await pdfToText(file);

          // Return the text file object
          return new File([text], file.name.replace(/\.pdf$/, ".txt"), {
            type: "text/plain",
          });
        } catch (error) {
          console.error(`Failed to convert ${file.name} to text:`, error);
          return null;
        }
      })
    );

    setPdfFilesToUpload(textFiles);
  };

  //////////////////////////////////////////////
  /////////////// functions ////////////////////
  //////////////////////////////////////////////

  const handleUpload = async (selectedFiles) => {
    if (!user && !accessToken) {
      console.log("No files selected");
      return;
    }

    if (!selectedProjectName) {
      ShowToast("Please select a project first");
      return;
    }

    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    formData.append("project_name", selectedProjectName);

    return axios
      .post(REACT_APP_BACKEND_URL + "api/pdf/upload/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        ShowToast("Files uploaded successfully!", "success");
        // toast.success("Files uploaded successfully!");
      })
      .catch((error) => {
        // toast.error("Error uploading files.");
        console.error("Error uploading files:", error);
        throw error;
      })
      .finally(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        dispatch(interfaceSlice.actions.setUploadingFiles(true));
      });
  };

  const handleClick = (e, item) => {
    e.stopPropagation();
    // If item is in the selectedPdfFiles array, remove it
    if (tickedPdfFiles.includes(item)) {
      dispatch(interfaceSlice.actions.removeTickedPdfFilesName(item));
    } else {
      dispatch(interfaceSlice.actions.addTickedPdfFilesName(item));
    }
    dispatch(interfaceSlice.actions.setSelectedComponent(null));
  };

  const handleSelectAll = () => {
    dispatch(interfaceSlice.actions.setTickedPdfFiles(selectedPdfFiles));
  };

  const handleClearAll = () => {
    dispatch(interfaceSlice.actions.setTickedPdfFiles([]));
  };

  const handleDeleteTickedPdfFiles = () => {
    dispatch(interfaceSlice.actions.setDeletingFiles(true));
    setPdfFilesToDelete(tickedPdfFiles);
  }

  return (
    <Grid item xs={3}>
    <Box
      sx={{
        height: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        borderRadius: 1,
        marginTop: 1,
        marginLeft: 1,
        border: "1px solid #ccc",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        pointerEvents: llmResponseLoading || llmRespondingToChat ? "none" : "auto", // Prevent interaction
      }}
    >
      <Box>
        <Tooltip title="Select all">
          <Button
            component="span"
            sx={{
              color: "gray",
              "&:hover": {
                color: "black",
                backgroundColor: "transparent",
              },
            }}
            disabled= {selectedPdfFiles.length === tickedPdfFiles.length}
            onClick={handleSelectAll}
          >
            <Typography
              sx={{
                fontSize: "13px",
              }}
            >
              Select all
            </Typography>
          </Button>
        </Tooltip>

        <Tooltip title="Clear all">
          <Button
            component="span"
            sx={{
              color: "gray",
              "&:hover": {
                color: "black",
                backgroundColor: "transparent",
              },
            }}
            disabled= {tickedPdfFiles.length === 0}
            onClick={handleClearAll}
          >
            <Typography sx={{ fontSize: "13px" }}>
              Clear
            </Typography>
          </Button>
        </Tooltip>


        <Tooltip title="Delete selected">
          <Button
            component="span"
            sx={{
              color: "gray",
              "&:hover": {
                color: "black",
                backgroundColor: "transparent",
              },
            }}
            disabled={tickedPdfFiles.length === 0}
            onClick={handleDeleteTickedPdfFiles}
          >
            <Typography sx={{ fontSize: "13px" }}>
              Delete
            </Typography>
          </Button>
        </Tooltip>
      </Box>
      
      
      <Box
        sx={{
          width: "100%",
          height: "95%",
          overflowY: "auto",
          overflowX: "hidden", // Prevent horizontal overflow
          marginBottom: 2,
          padding: 1,
        }}
      >

        {selectedPdfFiles.map((item, index) => {

          if (deletingFiles && pdfFilesToDelete.includes(item)) {
            return null;
          }

          return (
            <Card
              key={index}
              sx={{
                boxShadow: tickedPdfFiles.includes(item)
                  ? 4
                  : 1,
                "&:hover": {
                  boxShadow: 4,
                },
                marginBottom: 1,
              }}
              onClick={(e) => {handleClick(e, item)}}
            >


              <CardActionArea disableRipple disableTouchRipple>
                <CardContent
                  sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                    "&:last-child": {
                      paddingBottom: 1,
                    },
                  }}
                >

                  <Grid container spacing={2} alignItems="center">

                    <Grid item xs={11}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "13px" }}
                        noWrap
                      >
                        {item}
                      </Typography>
                    </Grid>

                    {
                      tickedPdfFiles.length > 0 && (
                        <Grid item xs={1}>
                          <RadioButtonCheckedIcon
                            sx={{
                            color: tickedPdfFiles.includes(item) ? "#696969" : "#E8E8E8",
                            fontSize: "0.8rem",
                            }}
                          />
                        </Grid>
                      )
                    }

                  </Grid>
                </CardContent>
                </CardActionArea>
            </Card>
          )
        })}

        {Object.keys(selectedPdfFiles).length === 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <Box
              width="60%"
              height="40%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              sx={{
                backgroundColor: "#F8F8F8",
                border: "3px dotted #A9A9A9",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => fileUploadRef.current.click()}
            >
              <Typography
                sx={{
                  justifyContent: "center",
                  color: "#A9A9A9",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                Upload an article start!
                <br />
                <br />
              </Typography>

              <PictureAsPdfIcon
                sx={{
                  fontSize: 80,
                  marginTop: 2,
                  color: "#E8E8E8",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "5%",
          margin: "0 auto",
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            id="pdf-upload-input"
            type="file"
            accept=".pdf,application/pdf"
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileUploadRef}
            disabled={
              (!user && !accessToken) || uploadingFiles || llmResponseLoading || llmRespondingToChat
            }
          />
          <label htmlFor="pdf-upload-input">
            <Button
              component="span"
              sx={{
                "&:disabled": {
                  backgroundColor: "#cccccc",
                },
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              disabled={
                (!user && !accessToken) || uploadingFiles || llmResponseLoading || llmRespondingToChat
              }
            >

              {!(uploadingFiles || deletingFiles) && (
                <Tooltip title="Upload Literature PDFs">
                  <CloudUploadOutlinedIcon
                    sx={{
                      color: "#909090",
                      fontSize: "2rem",
                      marginLeft: "5px",
                      "&:hover": {
                        transform: "scale(1.1)", // Slightly enlarge the icon on hover
                      },
                    }}
                  />
                </Tooltip>
              )}
            </Button>
          </label>
        </Box>
      </Box>
    </Box>
    </Grid>
  );
}
