

import { Box, Typography } from '@mui/material';

export default function Post2_HowToCreateLiteratureAnalysisTable() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Creating a literature analysis table is a crucial step in any research process. <br/>
        A literature analysis table helps you to organize and analyze the literature you have read. <br/>
        A typical literature analysis table contains the following columns: <br/>
        <ul>
          <li>Title</li>
          <li>Research Question</li>
          <li>Methodology</li>
          <li>Findings</li>
          <li>Practical Implications</li>
          <li>Future Research</li>
          <li>Source of Data</li>
          <li>Conclusion</li>
        </ul>

        Use PaperGPT to create a literature analysis table in seconds. <br/><br/>

        <b>Step 1:</b> Upload the papers you have read. <br/>
        <b>Step 2:</b> Click on the "Literature Review Analysis Table" section. <br/>
        <b>Step 3:</b> Click on the "Generate Literature Review Analysis Table" button. <br/><br/>

        And that's it! You will get a literature analysis table in seconds.
      </Typography>

      <img
        src={"/posts/post2/image1.png"}
        alt="How to Create Literature Analysis Table"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h6" gutterBottom>
        You can have a larger view of the literature analysis table by clicking on the "literature review analysis table" tag. <br/>
        You can also export the literature analysis table to a CSV file. Just click on the "Download" button.

      </Typography>

      <img
        src={"/posts/post2/image2.png"}
        alt="How to Export Literature Analysis Table"
        style={{
          width: "100%",
        }}
      />

    </Box>
  );
}