import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { 
    Container, 
    Typography, 
    Grid, 
    Paper, 
    TextField, 
    Button,
    Box
} from '@mui/material';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { checkAdminStatus } from '../utils/Endpoints';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


export default function AdminPanel() {

    const user = useSelector(state => state.user.user)
    const accessToken = useSelector(state => state.user.accessToken);

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const checkAdmin = async () => {
            const isAdmin = await checkAdminStatus(user, accessToken);
            setIsAdmin(isAdmin);
        };
        checkAdmin();
    }, [user, accessToken]);

    if (!isAdmin) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                overflow: 'auto',
                marginTop: "5vh"
            }}
        >
            <CreditsAdminPanel />
            <Box sx={{ mt: 4 }}>
                <PaymentHistoryAdminPanel />
            </Box>
        </Box>
    );
}



function CreditsAdminPanel() {
    
    const [credits, setCredits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredCredits, setFilteredCredits] = useState([]);

    const accessToken = useSelector(state => state.user.accessToken);
    const user = useSelector(state => state.user.user);

    const fetchCredits = async () => {
      if (!user && !accessToken) return;
      let idToken = null;
      if (user) {
        idToken = await user.getIdToken();
      } else {
        idToken = accessToken;
      }
    
      try {
          const response = await axios.get(
            REACT_APP_BACKEND_URL + 'api/auth/adminView/', {
              headers: {
                  'Authorization': `Bearer ${idToken}`
              }
          });
          setCredits(response.data);
          setLoading(false);
      } catch (err) {
          setError('Failed to fetch credits');
          setLoading(false);
      }
    };

    useEffect(() => {
      fetchCredits();
    }, [user, accessToken]);

    const handleRefresh = async () => {
      setLoading(true);
      fetchCredits();
    };

    const updateCredits = async (creditId, newAmount) => {

        if (!user && !accessToken) return;
        let idToken = null;
        if (user) {
          idToken = await user.getIdToken();
        } else {
          idToken = accessToken;
        }

        try {
            await axios.put(
              REACT_APP_BACKEND_URL + `api/auth/adminView/${creditId}/`, 
                { credits: newAmount },
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                }
            );
            await handleRefresh();
        } catch (err) {
            console.error('Failed to update credits:', err);
            setError('Failed to update credits');
        }
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        if (!searchTerm) {
            setFilteredCredits([]);
        } else {
            setFilteredCredits(
                credits.filter(credit => credit.user_email.toLowerCase().includes(searchTerm))
            );
        }
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Admin Panel - Credit Management
            </Typography>

            <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
                <Button variant="contained" onClick={handleRefresh}>
                    Refresh
                </Button>
                <TextField
                    placeholder="Search by email"
                    size="small"
                    onChange={handleSearch}
                />
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Email</TableCell>
                            <TableCell>Credits</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(filteredCredits.length > 0 ? filteredCredits : credits).map((credit) => (
                            <TableRow key={credit.user_email}>
                                <TableCell>{credit.user_email}</TableCell>
                                <TableCell>{credit.credits}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <TextField
                                            type="number"
                                            defaultValue={credit.credits}
                                            size="small"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    updateCredits(credit.id, e.target.value);
                                                }
                                            }}
                                        />
                                        <Button 
                                            variant="contained"
                                            onClick={(e) => {
                                                const input = e.target.parentElement.querySelector('input');
                                                updateCredits(credit.id, input.value);
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

function PaymentHistoryAdminPanel() {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [newPayment, setNewPayment] = useState({
        user_email: '',
        amount: '',
        credits_added: ''
    });

    const accessToken = useSelector(state => state.user.accessToken);
    const user = useSelector(state => state.user.user);

    const fetchPayments = async () => {
        if (!user && !accessToken) return;
        let idToken = null;
        if (user) {
            idToken = await user.getIdToken();
        } else {
            idToken = accessToken;
        }

        try {
            const response = await axios.get(
                REACT_APP_BACKEND_URL + 'api/auth/adminPaymentHistory/',
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                }
            );
            setPayments(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch payment history');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPayments();
    }, [user, accessToken]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        if (!searchTerm) {
            setFilteredPayments([]);
        } else {
            setFilteredPayments(
                payments.filter(payment => 
                    payment.user_email.toLowerCase().includes(searchTerm) ||
                    payment.stripe_session_id.toLowerCase().includes(searchTerm)
                )
            );
        }
    };

    const handleNewPaymentChange = (e) => {
        setNewPayment({
            ...newPayment,
            [e.target.name]: e.target.value
        });
    };

    const handleAddPayment = async () => {
        if (!user && !accessToken) return;
        let idToken = null;
        if (user) {
            idToken = await user.getIdToken();
        } else {
            idToken = accessToken;
        }

        try {
            await axios.post(
                REACT_APP_BACKEND_URL + 'api/auth/adminPaymentHistory/',
                newPayment,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                }
            );
            setNewPayment({
                user_email: '',
                amount: '',
                credits_added: ''
            });
            await fetchPayments();
        } catch (err) {
            setError('Failed to add payment');
        }
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Admin Panel - Payment History
            </Typography>

            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Add New Payment
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="User Email"
                            name="user_email"
                            value={newPayment.user_email}
                            onChange={handleNewPaymentChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Amount ($)"
                            name="amount"
                            type="number"
                            value={newPayment.amount}
                            onChange={handleNewPaymentChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Credits"
                            name="credits_added"
                            type="number"
                            value={newPayment.credits_added}
                            onChange={handleNewPaymentChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleAddPayment}
                            sx={{ height: '100%' }}
                        >
                            Add Payment
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Box sx={{ mb: 3 }}>
                <TextField
                    placeholder="Search by email or session ID"
                    size="small"
                    onChange={handleSearch}
                    sx={{ width: 300 }}
                />
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Email</TableCell>
                            <TableCell>Amount ($)</TableCell>
                            <TableCell>Credits Added</TableCell>
                            <TableCell>Session ID</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(filteredPayments.length > 0 ? filteredPayments : payments).map((payment) => (
                            <TableRow key={payment.id}>
                                <TableCell>{payment.user_email}</TableCell>
                                <TableCell>${payment.amount}</TableCell>
                                <TableCell>{payment.credits_added}</TableCell>
                                <TableCell>{payment.stripe_session_id}</TableCell>
                                <TableCell>{new Date(payment.created_at).toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}
