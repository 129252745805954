import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { blogPosts } from '../posts/blogPosts';

export default function BlogPostPage() {
  
  const { id } = useParams();
  
  // In a real app, you would fetch the blog post data based on the ID
  // For now, we'll use the static data
  const blogPost = blogPosts.find((post) => post.id === parseInt(id));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
    
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          height: "85vh",
          marginTop: "10vh",
          overflowY: "auto",
          width: "100%",
        }}
      >
        <Typography variant="h3" gutterBottom>
          {blogPost.title}
        </Typography>
        
        <Typography
          variant="subtitle1"
          color="textSecondary"
          gutterBottom
          sx={{
            marginBottom: "5vh",
          }}
        >
          {blogPost.date}
        </Typography>
        
        <Box
          sx={{
            maxWidth: '1200px',
            margin: '0 auto',
          }}
        >
          {blogPost.content}
        </Box>

      </Box>


      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "5vh",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/projects")}
          sx={{
            width: "300px",
            height: "40px",
            fontSize: "1.2rem",
            marginBottom: 1,
          }}
        >
          Try it
        </Button>
        </Box>

    </Box>
  );
} 