import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Avatar,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Star as StarIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { interfaceSlice } from "../store/interfaceSlice";
import { useSelector } from "react-redux";
import { userSlice } from "../store/userSlice";

const LandingPage = () => {

  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGetStarted = () => {

    if (user || accessToken) {
      navigate("/write-article");
    } else {
      navigate("/auth");
    }
  };

  useEffect(() => {
    dispatch(interfaceSlice.actions.setWhichPage("home-page"));
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "1000px",
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "60%",
          textAlign: "center",
          backgroundColor: "white",
          paddingTop: "200px",
        }}
      >
        <Typography variant="h1" gutterBottom>
          PaperGPT
        </Typography>

        <Typography variant="h3" gutterBottom>
          Intelligent Path to Publishing, from A to Z
        </Typography>

        <Typography
          variant="h3"
          gutterBottom
          color="#880808"
          sx={{
            mt: 2,
          }}
        >
          Made by scholars from Stanford and HKPolyU
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleGetStarted}
          sx={{
            mt: 4,
            width: "200px",
            height: "40px",
            fontSize: "1.2rem",
          }}
        >
          Get Started
        </Button>
      </Box>


      {/* Other universities Section */}
      <Box
        sx={{
          padding: "4rem 0",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          sx={{
            mb: 4,
          }}
        >
          Used by researchers from
        </Typography>
        
        <Grid container spacing={4}>
        {["stanford.jpg",
          "ucsf.jpg",
          "penn.png",
          "mit.jpg",
          "vt.png",
          "delft.png",
          // "epfl.png",
          "polyu.png",
          "tsinghua.png",
        ].map((uni) => (
          <Grid item xs={12} md={1.5} key={uni}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "20vh",
                maxHeight: "20vh",
              }}
            >
              <img src={`/universities/uni_${uni}`} style={{width: uni === "polyu.png" ? "50%" : (uni === "ucsf.jpg" || uni === "vt.png") ? "70%" : "100%"}}/>
            </Box>
          </Grid>
          ))
        }
        </Grid>
      </Box>

      {/* Features Section */}
      <Box>
        <Typography variant="h4" gutterBottom textAlign="center">
          PaperGPT Can Help You With
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          sx={{
            width: "100%",
            marginTop: 4,
          }}
        >
          <FeatureCard
            handleClick={() => navigate("/blog/1")}
          >
            <Typography variant="h6">
              Research Ideas
            </Typography>
          </FeatureCard>

          <FeatureCard
            handleClick={() => navigate("/blog/2")}
          >
            <Typography variant="h6">
              Literature Analysis Table
            </Typography>
          </FeatureCard>

          <FeatureCard
            handleClick={() => navigate("/blog/3")}
          >
            <Typography variant="h6">
              Where to Start Methodology
            </Typography>
          </FeatureCard>

          <FeatureCard
            handleClick={() => navigate("/blog/4")}
          >
            <Typography variant="h6">
              Draft the Results Section
            </Typography>
          </FeatureCard>

          <FeatureCard
            handleClick={() => navigate("/blog/5")}
          >
            <Typography variant="h6">
              Writing a Full Article
            </Typography>
          </FeatureCard>

          <FeatureCard
            handleClick={() => navigate("/blog/6")}
          >
            <Typography variant="h6">
              Peer Review for Journals
            </Typography>
          </FeatureCard>
        </Box>
          
        

      </Box>

      {/* How It Works Section */}
      <Box sx={{ padding: "4rem 0", backgroundColor: "#f5f5f5" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          How It Works
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Step 1
                </Typography>
                <Typography variant="body1">
                  Upload your PDF files to our platform. You can upload multiple
                  files at once.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Step 2
                </Typography>
                <Typography variant="body1">
                  Our AI analyzes the content and extracts the necessary
                  information.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Step 3
                </Typography>
                <Typography variant="body1">
                  Download the results or use them directly on our platform.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Pricing Section */}
      <Box sx={{ padding: "4rem 0" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Pricing
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Free Plan
                </Typography>
                <Typography variant="body1">
                  Basic features for personal use.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  $0/month
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Pro Plan
                </Typography>
                <Typography variant="body1">
                  Advanced features for professionals.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  $19.99/month
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Enterprise Plan
                </Typography>
                <Typography variant="body1">
                  All features for large teams and organizations.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Contact us for pricing
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Team Section */}
      <Box sx={{ padding: "4rem 0" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Meet Our Team
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ width: 100, height: 100, mb: 2 }}>JS</Avatar>
                  <Typography variant="h6">Jane Sally</Typography>
                  <Typography variant="body1">CEO & Co-Founder</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ width: 100, height: 100, mb: 2 }}>JD</Avatar>
                  <Typography variant="h6">John Doe</Typography>
                  <Typography variant="body1">CTO & Co-Founder</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* FAQ Section */}
      <Box sx={{ padding: "4rem 0", backgroundColor: "#f5f5f5" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Frequently Asked Questions
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  What is AI PDF Analyzer?
                </Typography>
                <Typography variant="body1">
                  AI PDF Analyzer is a tool that uses advanced AI technology to
                  analyze and extract information from PDF files.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  How does it work?
                </Typography>
                <Typography variant="body1">
                  Simply upload your PDF files, and our AI will analyze the
                  content and provide the necessary information.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Is there a free trial available?
                </Typography>
                <Typography variant="body1">
                  Yes, we offer a free plan with basic features for personal
                  use.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  How can I contact support?
                </Typography>
                <Typography variant="body1">
                  You can reach out to our support team by clicking the "Contact
                  Support" button in the Contact Us section.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Newsletter Signup Section */}
      <Box sx={{ padding: "4rem 0" }} hidden={true}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Stay Updated
        </Typography>
        <Typography variant="body1" textAlign="center" gutterBottom>
          Subscribe to our newsletter to get the latest updates and news.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField label="Email Address" variant="outlined" sx={{ mr: 2 }} />
          <Button variant="contained" color="primary" size="large">
            Subscribe
          </Button>
        </Box>
      </Box>

      {/* Contact Us Section */}
      <Box sx={{ padding: "4rem 0" }}>
        <Typography variant="h4" gutterBottom textAlign="center">
          Contact Us
        </Typography>
        <Typography variant="body1" textAlign="center" gutterBottom>
          Have questions or need support? Reach out to us!
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}
            onClick={()=> window.open("mailto:info@papergpt.app")}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            >
            Contact
          </Button>
        </Box>
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          padding: "2rem 0",
          textAlign: "center",
          backgroundColor: "#282c34",
          color: "#fff",
        }}
      >
        <Typography variant="body1">
          &copy; {new Date().getFullYear()} PaperGPT. All rights
          reserved.
        </Typography>
      </Box>
    </Container>
  );
};

export default LandingPage;


function FeatureCard({handleClick, children}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: "#f5f5f5",
        width: "15%",
        height: "200px !important",
        marginLeft: 2,
        marginRight: 2,
      }}
    >
      <Button
        variant="contained"
        color="transparent"
        sx={{
          width: "100%",
          height: "100% !important",
          textTransform: "none",
        }}
        onClick={handleClick}
      >
        {children}
      </Button>
    </Box>
  );
}