import React, { useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import {
  Grid,
} from "@mui/material";


import { pdfjs } from "react-pdf";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { interfaceSlice } from "../store/interfaceSlice";

import "@react-pdf-viewer/core/lib/styles/index.css";

import ArticleComponent from "../components/ArticleComponent";
import LiteratureComponent from "../components/LiteratureComponent";
import ChatBox from "../components/ChatBox";
import ProgressComponent from "../components/ProgressComponent";

import { projectSlice } from "../store/projectSlice";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

function PaperGPTAppPage() {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////


  const navigate = useNavigate();
  // This is the main article object

  const dispatch = useDispatch();
  const refetchGetAllProjects = useSelector(
    (state) => state.interface.refetchGetAllProjects
  );

  // User states
  const user = useSelector((state) => state.user.user);
  const userEmail = useSelector((state) => state.user.userEmail);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );

  const accessToken = useSelector((state) => state.user.accessToken);
  const selectedPdfFiles = useSelector(
    (state) => state.interface.selectedPdfFiles
  );
  const refetchGetAllPdfFiles = useSelector(
    (state) => state.interface.refetchGetAllPdfFiles
  );

  const projectKey = useSelector((state) => state.project.projectKey);
  const chatKey = useSelector((state) => state.project.chatKey);
  const article = useSelector((state) => state.project.article);
  const chatHistory = useSelector((state) => state.project.chatHistory);


  ///////////////////////////////////////////////////////////////////
  ///////////////////// useEffect ///////////////////////////////////
  //////////////////////////////////////////////////////////////////

  useEffect(() => {

    if (selectedProjectName === null ||
      selectedProjectName === "" ||
      selectedProjectName === undefined
    ){
      // navigate("/projects");
      // dispatch(interfaceSlice.actions.setWhichPage("projects"));
    }
    dispatch(interfaceSlice.actions.setWhichPage("write-article"));
    return () => {
      dispatch(interfaceSlice.actions.setWhichPage(null));
    };
  }, []);

  useEffect(() => {
    if (userEmail && selectedProjectName) {
      dispatch(projectSlice.actions.setProjectKey({userEmail, selectedProjectName}));
      dispatch(projectSlice.actions.setChatKey({userEmail, selectedProjectName}));
    }

  }, [userEmail, selectedProjectName]);

  useEffect(() => {
    localStorage.setItem(chatKey, JSON.stringify(chatHistory));
  }, [chatHistory]);

  useEffect(() => {
    if (article) {
      localStorage.setItem(projectKey, JSON.stringify(article));
    }
  }, [article]);


  // In the case that the userEmail or selectedProjectName is not set, we don't want to render the page
  if (!projectKey || !chatKey){
    return <></>;
  }

  return (
    <Grid 
      container
      spacing={0}
      direction="row"
      sx={{
        width: "100%",
        minHeight: "1000px",
        marginTop: "50px",
      }}
      padding={0}
    >
      {selectedProjectName && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            minHeight: "950px",
            height: "95vh !important",
            padding: 0,
            margin: 0,
          }}
        >

          {/* PDF display section */}
          <LiteratureComponent />
            
          {/* The middle section grows to fill the rest of the space */}
          <ArticleComponent />
            
          {/* The right side paper section */}
          <ChatBox />
        </Box>
      )}

      <ProgressComponent />
    </Grid>
  );
}

export default PaperGPTAppPage;
