import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import { generate } from 'random-words';
import moment from "moment";

import {
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";


import axios from "axios";
import { pdfjs } from "react-pdf";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { handleSaveNewProject } from "../utils/Endpoints";

import { userSlice } from "../store/userSlice";
import { projectSlice } from "../store/projectSlice";
import { interfaceSlice } from "../store/interfaceSlice";


const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

export default function ProjectsPage() {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  // This is the main article object

  const dispatch = useDispatch();
  const refetchGetAllProjects = useSelector(
    (state) => state.interface.refetchGetAllProjects
  );

  // User states
  const user = useSelector((state) => state.user.user);
  const projects = useSelector((state) => state.user.projects);

  const accessToken = useSelector((state) => state.user.accessToken);
  const refetchGetAllPdfFiles = useSelector(
    (state) => state.interface.refetchGetAllPdfFiles
  );
  ///////////////////////////////////////////////////////////////////
  ///////////////////// useEffect ///////////////////////////////////
  //////////////////////////////////////////////////////////////////

  return (
    <Grid 
      container
      spacing={0}
      direction="row"
      sx={{
        width: "100%",
        marginTop: "50px",
        minHeight: "1000px",
        height: "100%",
      }}
      padding={0}
    >
      <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            minHeight: "980px !important",
            marginBottom: "20px",
        }}
      >
        <Grid item xs={12}>

          <Box
            display="flex"
            flexDirection="column"
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 1,
              marginTop: 1,
              marginLeft: 1,
              marginRight: 1,
              border: "1px solid #ccc",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            }}
          >

            <Box
              sx={{
                marginBottom: 2,
                paddingLeft: 2,
                paddingTop: 2,
              }}
            >
              <Typography variant="h4">Projects</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {projects.slice().reverse().map((project) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                  />
                ))}

              <NewProjectCard />
            </Box>
            
          </Box>
        </Grid>
      </Box>
    </Grid>
    
  );
}


const ProjectCard = ({ project }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.accessToken);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );
  const refetchGetAllProjects = useSelector(
    (state) => state.interface.refetchGetAllProjects
  );

  const handleClick = () => {
    dispatch(interfaceSlice.actions.setRefetchGetAllPdfFiles());
    dispatch(userSlice.actions.setSelectedProjectName(project.name));
    navigate("/write-article");
  }

  const handleDeleteProject = async (project, event) => {
    event.stopPropagation();
    try {
      const projectId = project.id;
      const response = await axios.delete(
        `${REACT_APP_BACKEND_URL}api/pdf/project/${projectId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (selectedProjectName === project.name) {
        dispatch(userSlice.actions.setSelectedProjectName(null));
        dispatch(interfaceSlice.actions.setSelectedPdfFiles([]));
      }

      dispatch(
        interfaceSlice.actions.setRefetchGetAllProjects(!refetchGetAllProjects)
      );
    } catch (error) {
      console.error("Error deleting project:", error);
    }

    dispatch(projectSlice.actions.deleteProject());
  };

  

  return (
    
    <Box
      sx={{
        margin: 1,
        width: 300,
        height: 200,
        '&:hover': {
          boxShadow: 5,
          cursor: "pointer",
        },
        border: "1px solid #909090",
        borderRadius: 2,
        boxShadow: 2,
      }}
      onClick={handleClick}
    >

      <Box
        sx={{
          height: "10%",
          backgroundColor: "",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: 1,
        }}
      >
        <Tooltip title="Delete Project (Irreversible)">
          <ClearIcon
            sx={{
              color: "#909090",
              '&:hover': {
                color: "black",
                cursor: "pointer",
              }
            }}
            onClick={(event) => handleDeleteProject(project, event)}
          />
        </Tooltip>
      </Box>

      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90%",
          marginTop: "-5%",
        }}
      >
        <Typography variant="h5">{project.name}</Typography>
        <Typography variant="body1">
          Created: {moment(project.created_at).format('YYYY/MM/DD')}
        </Typography>
          
      </Box>


    </Box>
    
  );
};

const NewProjectCard = ({ }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.accessToken);
  const refetchGetAllProjects = useSelector(
    (state) => state.interface.refetchGetAllProjects
  );
  const refetchGetAllPdfFiles = useSelector(
    (state) => state.interface.refetchGetAllPdfFiles
  );

  const handleClick = () => {

    const word1 = generate();
    const word2 = generate();
    const newProjectName = `${word1} ${word2}`;

    dispatch(interfaceSlice.actions.setRefetchGetAllPdfFiles());
    dispatch(userSlice.actions.setSelectedProjectName(newProjectName));

    handleSaveNewProject(
      dispatch,
      interfaceSlice,
      newProjectName,
      accessToken,
      refetchGetAllProjects,
      userSlice
    )
    navigate("/write-article");
  }


  return (
    
    <Tooltip title="Create New Project">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: 1,
          width: 300,
          height: 200,
          '&:hover': {
            boxShadow: 5,
            cursor: "pointer",
          },
          boxShadow: 2,
          borderRadius: 2,
          border: "1px solid #909090",
        }}
        onClick={handleClick}
      >
        <CardContent>
            <AddIcon
              sx={{
                fontSize: 60,
                color: "#909090",
              }}
            />
        </CardContent>
      </Box>
    </Tooltip>
    
  );
};