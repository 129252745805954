import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const ResendConfirmationEmail = ({ open, onClose, message, setMessage }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          REACT_APP_BACKEND_URL + "/api/auth/email-verify/",
          values
        );

        setMessage(
          "Confirmation email has been resent. Please check your inbox."
        );


      } catch (error) {
        setMessage("Failed to resend confirmation email. Please try again.");
      }
    },
  });

  useEffect(() => {
    // when the component unmounts set the message to an empty string
    return () => {
      setMessage(null);
    };
  }, [setMessage]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle align="center">Resend Confirmation Email</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", maxWidth: "400px" }}
        >
          {message ? (
            <Typography>{message}</Typography>
          ) : (
            <>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{
                  marginTop: 2,
                }}
              />
              <Button
                disabled={message}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                  marginTop: 2,
                }}
              >
                Resend Email
              </Button>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResendConfirmationEmail;
