import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Grid,
  Paper,
  Alert,
  Collapse,
  CircularProgress,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { interfaceSlice } from "../store/interfaceSlice";

import ReactTypingEffect from 'react-typing-effect';

import ResendConfirmationEmail from "../ResendConfirmationEmail"; // Import the component
import CheckYourEmail from "../components/CheckYourEmail";
import { userSlice } from "../store/userSlice";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const BackgroundContainer = styled(Box)({
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(135deg, gray 0%, lightgray 100%)",
  padding: "0 16px",
  overflow: "hidden",
});

const Logo = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#3f51b5",
  textAlign: "center",
  marginBottom: "10px",
});

const AuthPage = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const recaptchaRef = useRef(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false); // State to toggle ResendConfirmationEmail component
  const [message, setMessage] = useState(null);
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const navigateToHomeAndSignIn = async () => {
    navigate("/");
  };
  const dispatch = useDispatch();

  const signupValidationSchema = yup.object({
    name: yup
      .string("Enter your name")
      .min(3, "Name should be of minimum 3 characters length")
      .required("Name is required"),
    field_of_study: yup
      .string("Enter your field of study")
      .required("Field of study is required")
      .oneOf(majors, 'Please select a valid field of study'),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid email"
      )
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  const loginValidationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Enter a valid email"
      )
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "11111111111",
    },
    validationSchema: isRegistering
      ? signupValidationSchema
      : loginValidationSchema,
    onSubmit: async (values) => {
      if (!captchaToken) {
        setCaptchaError(true); // Set the error state if reCAPTCHA is not clicked
        return;
      }

      setError("");
      setLoading(true);
      setCooldown(true);

      if (isRegistering) {
        try {
          const response = await axios.post(
            `${REACT_APP_BACKEND_URL}api/auth/register-recaptcha/`,
            {
              ...values,
              phone: "1111111111",
              captcha_token: captchaToken,
            }
          );

          setEmail(values.email);
          setIsRegistering(false);
          dispatch(userSlice.actions.setCheckEmail(true));
        } catch (error) {
          console.error("Error registering user: ", error);
          if (
            error.response &&
            error.response.data.error === "User already exists"
          ) {
            setError(
              "Email already exists. Please register with a different email address"
            );
          } else if (
            error.response &&
            error.response.data.error === "Please use your university email"
          ) {
            setError("Please use your university email");
          } else {
            setError("Failed to register. Please try again.");
          }
        } finally {
          setLoading(false);
          setCaptchaToken("");
          recaptchaRef.current.reset();
        }
      } else {
        try {
          const response = await axios.post(
            `${REACT_APP_BACKEND_URL}api/auth/login/`,
            {
              username: values.email,
              password: values.password,
              captcha_token: captchaToken,
            }
          );

          localStorage.setItem("userEmail", response.data.email);
          dispatch(userSlice.actions.setUserEmail(response.data.email));

          localStorage.setItem("accessToken", response.data.access);
          dispatch(userSlice.actions.setAccessToken(response.data.access));

          localStorage.setItem("refreshToken", response.data.refresh);
          dispatch(userSlice.actions.setRefreshToken(response.data.refresh));

          navigate("/projects");
          
        } catch (error) {
          console.error("Error logging in: ", error.response.data.error[0]);
          if (
            error.response &&
            error.response.data.error[0] === "email or password is incorrect"
          ) {
            setError("Email or password is incorrect. Please try again.");
          } else if (
            error.response &&
            error.response.data.error[0] === "user is not activated"
          ) {
            setError(
              "User is not activated. Please check your email and activate your account."
            );
          } else {
            setError("Failed to login. Please try again.");
          }
        } finally {
          setLoading(false);
          setCaptchaToken("");
          recaptchaRef.current.reset();
        }
      }
    },
  });

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  useEffect(() => {
    dispatch(interfaceSlice.actions.setWhichPage("auth-page"));
    return () => {
      dispatch(interfaceSlice.actions.setWhichPage(null));
    };
  }, []);

  useEffect(() => {
    if (captchaToken) {
      setCaptchaError(false);
    }
  }, [captchaToken]);
  const setShowResendEmailFunction = () => {
    setShowResendEmail(!showResendEmail);
  };

  useEffect(() => {
    if (!captchaToken) {
      recaptchaRef.current.execute(); // Execute the invisible captcha on load
    }
  }, []);

  useEffect(() => {
    if (cooldown) {
      const timer = setTimeout(() => {
        setCooldown(false);
      }, 2000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [cooldown]);

  useEffect(() => {
    async function checkAuthenticity() {
      let idToken = null;

      if (user || accessToken) {
        if (user) {
          idToken = user.getIdToken();
        } else {
          idToken = accessToken;
        }

        await axios
          .get(REACT_APP_BACKEND_URL + "api/auth/profile-simple", {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              navigate("/projects");
            } else {
              // navigate("/auth");
              // handleSignOut();
            }
          })
          .catch((error) => {
            console.error("Error getting user:", error);
          });
      }
    }

    checkAuthenticity();
  }, [user, accessToken]);

  
  return (
    <BackgroundContainer>
    <Box
      display="flex"
      flexDirection="row"
      height="100%"
    >

      <Box
        component="main"
        maxWidth="xs"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          width: "50%",
        }}
      >
       
        <Paper
          elevation={6}
          sx={{
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: 2,
          }}
        >
          <Logo
            sx={{
              fontSize: "36px",
              color: "#696969",
            }}
          >PaperGPT</Logo>
          <Box 
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            <form
              onSubmit={formik.handleSubmit}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {isRegistering && (
                <TextField
                  disabled={loading || cooldown}
                  label="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                  margin="normal"
                  required
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                />
              )}

              {isRegistering && (
                <Autocomplete
                  fullWidth
                  disabled={loading || cooldown}
                  options={majors}
                  value={formik.values.field_of_study || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('field_of_study', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Field of Study"
                      name="field_of_study"
                      error={formik.touched.field_of_study && Boolean(formik.errors.field_of_study)}
                      helperText={formik.touched.field_of_study && formik.errors.field_of_study}
                      fullWidth
                      margin="normal"
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}

              <TextField
                disabled={loading || cooldown}
                label="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                margin="normal"
                required
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                disabled={loading || cooldown}
                label="Password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
                margin="normal"
                required
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", // Center horizontally
                  width: "100%",
                }}
              >
                {!loading && !cooldown && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // width: "100%",
                      border: captchaError ? "2px solid #d32f2f" : "0px",
                      borderRadius: 1,
                    }}
                  >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_SITE_KEY}
                      size="invisible"
                      onChange={handleCaptchaChange}
                    />
                  </Box>
                )}
              </Box>

              <Button
                type="submit"
                variant="contained"
                disabled={loading || cooldown}
                sx={{
                  width: "60%",
                  fontSize: "14px",
                  margin: 1,
                  borderRadius: 10,
                  backgroundColor: "primary",
                  textTransform: "none",
                }}
              >
                {loading || cooldown ? (
                  <CircularProgress size={24} color="inherit" />
                ) : isRegistering ? (
                  "Register"
                ) : (
                  "Sign In"
                )}
              </Button>

            </form>

            <Box
              display="flex"
              flexDirection="row"
              width="100%"
            >
              <Button
                sx={{
                  width: "50%",
                  color: "#3f51b5",
                  borderColor: "#3f51b5",
                  textTransform: "none",
                  fontSize: "12px",
                  "&:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                  }
                }}
                onClick={() => {
                  setIsRegistering(!isRegistering);
                  formik.resetForm();
                }}
              >
                {isRegistering
                  ? "Have an account?"
                  : "Don't have an account?"}
              </Button>

              <Button
                sx={{
                  width: "50%",
                  textTransform: "none",
                    color: "#3f51b5",
                    fontSize: "12px",
                    "&:hover": {
                      backgroundColor: "transparent",
                      textDecoration: "underline",
                    }
                  }}
                  onClick={() => {
                    setShowResendEmail(!showResendEmail);
                  }}
                >
                Resend Activation Link
              </Button>

            </Box>

          </Box>
        </Paper>        
      </Box>

      <ResendConfirmationEmail
        open={showResendEmail}
        onClose={() => setShowResendEmailFunction(false)}
        message={message}
        setMessage={setMessage}
      />
      <CheckYourEmail email={email} />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "50%",
        }}
      >
        <Paper
          elevation={6}
          sx={{
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            borderRadius: 2,
            width: "100%",
            height: "100%",
          }}
        >

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Box
              sx={{
                fontSize: "80px",
                fontWeight: "bold",
                color: "#303030",
                textAlign: "left",
              }}
            >
              For academics <br/>
              who value <br/>

              <ReactTypingEffect
                text={['time.', 'efficiency.', 'knowledge.']}
                speed={100}
                eraseSpeed={100}
                eraseDelay={3000}
                typingDelay={1000}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '80px',
                }}
                displayTextRenderer={(text) => {
                  return (
                    <span
                      style={{
                        color: '#6F8FAF',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        fontSize: '80px',
                      }}
                    >
                      {text}
                    </span>
                  );
                }}
              />
            </Box>

            
          </Box>

        </Paper>
      </Box>

    </Box>
    </BackgroundContainer>
  );
};

export default AuthPage;

// Make it one column
const majors = [
  'Accounting',
  'Actuarial Science',
  'Aerospace Engineering',
  'Agricultural Engineering',
  'Agriculture',
  'Animal Science',
  'Anthropology',
  'Applied Mathematics',
  'Architecture',
  'Artificial Intelligence',
  'Astronomy',
  'Biochemistry',
  'Biology',
  'Biomedical Engineering',
  'Biotechnology',
  'Botany',
  'Business Administration',
  'Chemical Engineering',
  'Chemistry',
  'Civil Engineering',
  'Classical Languages',
  'Comparative Literature',
  'Computer Science',
  'Criminal Justice',
  'Culinary Arts',
  'Cybersecurity',
  'Dance', 
  'Data Science',
  'Digital Communication',
  'Early Childhood Education',
  'Earth Sciences',
  'Economics',
  'Education',
  'Electrical Engineering',
  'English Literature',
  'Entrepreneurship',
  'Environmental Engineering',
  'Environmental Science',
  'Ethics',
  'Event Management',
  'Fashion Design',
  'Film Studies',
  'Finance',
  'Fine Arts', 
  'Food Science', 
  'Forestry', 
  'Genetics', 
  'Geography', 
  'Geology', 
  'Graphic Design', 
  'Healthcare Administration', 
  'History', 
  'Horticulture', 
  'Hospitality Management', 
  'Human Resource Management', 
  'Industrial Engineering', 
  'Information Technology', 
  'Interior Design', 
  'International Business', 
  'International Relations', 
  'Journalism', 
  'Landscape Architecture', 
  'Linguistics', 
  'Marine Biology', 
  'Marketing', 
  'Mass Communication', 
  'Materials Engineering', 
  'Mathematical Physics', 
  'Mechanical Engineering', 
  'Media Studies', 
  'Meteorology', 
  'Microbiology', 
  'Mining Engineering', 
  'Modern Languages', 
  'Music', 
  'Network Engineering', 
  'Neuroscience', 
  'Nuclear Engineering', 
  'Nursing', 
  'Nutrition', 
  'Occupational Therapy', 
  'Oceanography', 
  'Performing Arts', 
  'Petroleum Engineering', 
  'Pharmacy', 
  'Philosophy', 
  'Photography', 
  'Physical Therapy', 
  'Physics', 
  'Political Science',
  'Psychology', 
  'Public Health', 
  'Public Policy', 
  'Public Relations', 
  'Pure Mathematics', 
  'Religious Studies', 
  'Social Work', 
  'Sociology', 
  'Software Engineering', 
  'Special Education', 
  'Speech Therapy', 
  'Statistics', 
  'Supply Chain Management',
  'Systems Engineering',
  'Theater', 
  'Theology', 
  'Tourism Management', 
  'Urban Planning', 
  'Urban Studies', 
  'Visual Arts', 
  'Wildlife Conservation', 
  'Zoology'
]