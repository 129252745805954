// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const REACT_APP_firebaseConfig_apiKey =
  process.env.REACT_APP_firebaseConfig_apiKey;

const REACT_APP_firebaseConfig_authDomain =
  process.env.REACT_APP_firebaseConfig_authDomain;
const REACT_APP_firebaseConfig_projectId =
  process.env.REACT_APP_firebaseConfig_projectId;

const REACT_APP_firebaseConfig_storageBucket =
  process.env.REACT_APP_firebaseConfig_storageBucket;

const REACT_APP_firebaseConfig_messagingSenderId =
  process.env.REACT_APP_firebaseConfig_messagingSenderId;
const REACT_APP_firebaseConfig_appId =
  process.env.REACT_APP_firebaseConfig_appId;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: REACT_APP_firebaseConfig_apiKey,
  authDomain: REACT_APP_firebaseConfig_authDomain,
  projectId: REACT_APP_firebaseConfig_projectId,
  storageBucket: REACT_APP_firebaseConfig_storageBucket,
  messagingSenderId: REACT_APP_firebaseConfig_messagingSenderId,
  appId: REACT_APP_firebaseConfig_appId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
