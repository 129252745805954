

import { Box, Typography } from '@mui/material';

export default function Post4_GetIdeasAboutTheStructureOfResults() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>

        Sometimes, we need to get ideas about the structure of results. <br/>
        We may not know how to interpret the results or how to write them in a scientific paper. <br/><br/>

        In this case, you can use PaperGPT to get ideas how to draft the results section. <br/><br/>

        <b>Step 1:</b> Upload the papers you have read. <br/>
        <b>Step 2:</b> Add the methodology and research gap. <br/>
        <b>Step 3:</b> Click on the "Draft a results section..." button. <br/><br/>

        And that's it! You will get a scientific results section tailored to your research question and methodology in seconds.

        <br/><br/>

        <b>Dependencies</b>
        <ul>
          <li>Research gap</li>
          <li>Methodology</li>
        </ul>

        <b>Optional</b>
        <ul>
          <li>A query in the results section</li>
        </ul>


        <br/><br/>

        <b>Note:</b> Results section is written based on the research gap, methodology, and the literature you have read.
        The more detailed information you provide, the better the results section will be.

      </Typography>

      <img
        src={"/posts/post4/image1.png"}
        alt="How to Get Ideas About the Structure of Results"
        style={{
          width: "100%",
        }}
      />

    </Box>
  );
}