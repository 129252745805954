import axios from "axios";
import ShowToast from "../components/toast";
import { interfaceSlice } from "../store/interfaceSlice";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "react-toastify/dist/ReactToastify.css";
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const handleDeletePdf = async (
  dispatch,
  user,
  accessToken,
  fileName,
  currentSelectedPdfFiles,
  actionFunction,
  projectName,
  shouldRefetch = true
) => {
  if (!user && !accessToken) return;
  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  await axios
    .delete(REACT_APP_BACKEND_URL + `api/pdf/delete-pdf-file/${fileName}/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        project_name: projectName,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        if (shouldRefetch) {
          dispatch(interfaceSlice.actions.setRefetchGetAllPdfFiles());
        }

        if (currentSelectedPdfFiles.includes(fileName)) {
          const newSelectedPdfFiles = currentSelectedPdfFiles.filter(
            (name) => name !== fileName
          );
          dispatch(actionFunction(newSelectedPdfFiles));
        }
      }
    })
    .catch((error) => {
      console.error("Error deleting PDF file:", error);
    });
};

export const handleSaveNewProject = async (
  dispatch,
  interfaceSlice,
  newProjectName,
  accessToken,
  refetchGetAllProjects,
  userSlice
) => {
  if (newProjectName === "") {
    ShowToast("Please enter a project name.");
    return;
  }

  await axios
    .post(
      REACT_APP_BACKEND_URL + "api/pdf/project/",
      {
        name: newProjectName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      dispatch(
        interfaceSlice.actions.setRefetchGetAllProjects(!refetchGetAllProjects)
      );

      dispatch(userSlice.actions.setSelectedProjectName(newProjectName));
      dispatch(
        interfaceSlice.actions.setRefetchGetAllPdfFiles()
      );
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        if (
          error.response.data.error === "Project with that name already exists"
        ) {
          ShowToast("Project with that name already exists.");
        }
      }
      console.error("Error adding project:", error);
    });
};



export const handleRenameProject = async (
  dispatch,
  newProjectName,
  accessToken,
  userSlice,
  pk
) => {

  if (newProjectName === "") {
    ShowToast("Please enter a project name.");
    return;
  }

  await axios
    .put(
      REACT_APP_BACKEND_URL + "api/pdf/project/",
      {
        new_name: newProjectName,
        pk: pk,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      dispatch(userSlice.actions.setSelectedProjectName(newProjectName));
    })
    .catch((error) => {
      if (error && error.response && error.response.data) {
        if (
          error.response.data.error === "Project with that name already exists"
        ) {
          ShowToast("Project with that name already exists.");
        }
      }
      console.error("Error adding project:", error);
    });

  return true;
};



export const postWriteArticle = async (request, user, accessToken) => {

  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  try {
    const response = await fetch(
      `${REACT_APP_BACKEND_URL}api/pdf/write-article/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );

    if (response.status === 402) {
      return "Not enough credits. Please upgrade.";
    }

    return await response.text();

  } catch (error) {
    if (error.name === "AbortError") {
      ShowToast("Writing article stopped");
      return null;

    } else {
      console.error("Error writing article: ", error);
      ShowToast("Error writing article");
      return null;
    }
  }
}


export const getUserCredit = async (user, accessToken, userSlice) => {
  if (!user && !accessToken) return;
  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  try {
    const response = await axios.get(
      REACT_APP_BACKEND_URL + "api/auth/credits",
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        }
      }
    );
    return response.data.credits;

  } catch (error) {
    console.error("Error getting all PDF files:", error);
    return null;
  }
};



export const getAllProjects = async (user, accessToken) => {
  
  if (!user && !accessToken) {
    return [];
  }

  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  try {
    const response = await axios.get(
      REACT_APP_BACKEND_URL + "api/pdf/project/",
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    const sortedData = response.data.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    return sortedData;

  } catch (error) {
    if (error && error.response && error.response.status === 403) {
      return "Not authorized";
    } else {
      console.error("Error getting all projects:", error);
    }
  }
};

export const getAllPdfFiles = async (user, accessToken, request) => {

  if (!user && !accessToken) {
    return [];
  }

  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  try {
    const response = await axios.get(
      REACT_APP_BACKEND_URL + "api/pdf/getallpdfs/",
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        params: request,
      }
    );

    return response.data.files.sort();
    
  } catch (error) {
    if (error && error.response && error.response.status === 403) {
      return "Not authorized";
    } else if (error && error.response === "Project not found" && error.response.status === 404) {
      return "Project not found";
    } else {
      console.error("Error getting all PDF files:", error);
    }
  }
};


export const fetchModifyComponents = async (user, accessToken, request) => {

  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  const response = await fetch(
    REACT_APP_BACKEND_URL + "api/pdf/modify-components/",
    {
      method: "POST",
      headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    }
  );

  if (response.status === 402) {
    return "Not enough credits";
  }

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const response_text = await response.text()

  return response_text;
};


export const fetchChatPdf = async (user, accessToken, request) => {

  if (!user && !accessToken) {
    return;
  }

  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  try {
    const response = await fetch(
      REACT_APP_BACKEND_URL + "api/pdf/chatpdfs/",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      }
    );

    if (response.status === 402) {
      return "Not enough credits";
    }

    if (!response.ok) {
      console.error("Error in chatpdfs", response)
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.text();

  } catch (error) {
    console.error("Error in chatpdfs", error);
  }
};

export const loadUser = async (accessToken) => {

  try {
    const response = await axios.get(
      REACT_APP_BACKEND_URL + "api/auth/profile-simple",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data = {
      displayName: response.data.name,
      email: response.data.email,
      photoURL: null,
      name: response.data.name,
      field_of_study: response.data.field_of_study,
      is_paid_user: response.data.is_paid_user,
    };

    return data;
  } catch (error) {
    console.error("Error getting user:", error);
  }
};

export const checkAdminStatus = async (user, accessToken) => {
  if (!user && !accessToken) return false;
  let idToken = null;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = accessToken;
  }

  try {
    const response = await axios.get(
      REACT_APP_BACKEND_URL + "api/auth/admin-check/",
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        }
      }
    );
    return response.data['is_admin'];

  } catch (error) {
    console.error("Error checking admin status:", error);
    return false;
  }
};