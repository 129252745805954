import React from "react";
import {
  Avatar,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { handleSignOut } from "../utils/Auth";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { interfaceSlice } from "../store/interfaceSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { loadUser } from "../utils/Endpoints";


const ProfileCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  height: "95%",
  overflow: "auto",
  margin: "auto",
  marginTop: theme.spacing(1),
  textAlign: "center",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.spacing(1),
  backgroundColor: "#ffffff",
  border: "1px solid #e0e0e0",
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(14),
  height: theme.spacing(14),
  margin: "auto",
  marginBottom: theme.spacing(2),
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
}));

const ProfileButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#007BFF",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
}));

const SignOutButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#FF0000",
  color: "black",
  border: "none",
  "&:hover": {
    backgroundColor: "#cc0000",
  },
}));

export default function Profile({ handleClose }) {
  const user = useSelector((state) => state.user.user);
  const [simpleUser, setSimpleUser] = useState(null);
  const accessToken = useSelector((state) => state.user.accessToken);
  const isPaidUser = useSelector((state) => state.user.isPaidUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignoutWithNavigate = async () => {
    dispatch(interfaceSlice.actions.setProfileModalOpen(false));
    navigate("/");
  };

  const handleSignoutWithNavigateJWT = async () => {
    dispatch(interfaceSlice.actions.setProfileModalOpen(false));
    navigate("/auth");
    await handleSignOut();
  };

  const handleSignOutClick = async () => {
    navigate("/auth");
    await handleSignOut();
  };
  
  useEffect(() => {
    if (accessToken) {
      loadUser(accessToken).then((data) => {
        setSimpleUser(data);
      });
    }
  }, [accessToken]);

  if (!user && !simpleUser) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f0f2f5"
      >
        <CircularProgress />
      </Box>
    );
  }
  
  
  return (
    <ProfileCard>
      <CardContent>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ProfileAvatar alt={simpleUser.name} src={simpleUser.photoURL} />
        <Typography variant="h5" component="div" gutterBottom>
          {simpleUser.displayName}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {simpleUser.email}
        </Typography>

        <Box mt={2}>
          <SignOutButton
            variant="outlined"
            onClick={handleSignOutClick}  
          >
            Sign Out
          </SignOutButton>
        </Box>

        {/* Account Details Section */}
        <Box mt={4} textAlign="left">
          <Typography variant="h6" gutterBottom>
            Account Details
          </Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemText
                primary="Full Name"
                secondary={simpleUser.displayName}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Email" secondary={simpleUser.email} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Field of Study"
                secondary={simpleUser.field_of_study || "N/A"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Account Type"
                secondary={isPaidUser ? "Premium User" : "Free User"}
              />
            </ListItem>
          </List>
        </Box>

        {/* Settings Section */}
        {/* <Box mt={4} textAlign="left">
          <Typography variant="h6" gutterBottom>
            Settings
          </Typography>
          <Divider />
          <List>
            <ListItem button>
              <ListItemText primary="Change Password" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Notification Preferences" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Privacy Settings" />
            </ListItem>
          </List>
        </Box> */}
      </CardContent>
    </ProfileCard>
  );
};