import Post1_HowToGenerateResearchIdea from './Post1_HowToGenerateResearchIdea';
import Post2_HowToCreateLiteratureAnalysisTable from './Post2_HowToCreateLiteratureAnalysisTable';
import Post3_GetIdeasHowToConductYourResearch from './Post3_GetIdeasHowToConductYourResearch';
import Post4_GetIdeasAboutTheStructureOfResults from './Post4_GetIdeasAboutTheStructureOfResults';
import Post5_HowToWriteAFullArticle from './Post5_HowToWriteAFullArticle';
import Post6_HowToPeerReviewForJournal from './Post6_HowToPeerReviewForJournal';



const blogPosts = [
  {
    id: 1,
    title: 'Generate Research Ideas',
    date: 'December 5, 2024',
    excerpt: 'Learn how to generate research ideas with PaperGPT',
    content: <Post1_HowToGenerateResearchIdea />,
  },
  {
    id: 2,
    title: 'Create Literature Analysis Table',
    date: 'December 5, 2024',
    excerpt: 'Learn how to create literature analysis table with PaperGPT',
    content: <Post2_HowToCreateLiteratureAnalysisTable />,
  },
  {
    id: 3,
    title: 'Get Ideas How to Conduct Your Research',
    date: 'December 5, 2024',
    excerpt: 'Learn how to get ideas how to conduct your research with PaperGPT',
    content: <Post3_GetIdeasHowToConductYourResearch />,
  },
  {
    id: 4,
    title: 'Get Ideas About the Structure of Results',
    date: 'December 6, 2024',
    excerpt: 'Learn how to get ideas about the structure of results with PaperGPT',
    content: <Post4_GetIdeasAboutTheStructureOfResults />,
  },
  {
    id: 5,
    title: 'How to Write a Full Article',
    date: 'December 6, 2024',
    excerpt: 'Learn how to write a full article with PaperGPT',
    content: <Post5_HowToWriteAFullArticle />,
  },
  {
    id: 6,
    title: 'How to Peer Review for Journal',
    date: 'December 6, 2024',
    excerpt: 'Learn how to peer review for journal with PaperGPT',
    content: <Post6_HowToPeerReviewForJournal />,
  },
];


export { blogPosts };