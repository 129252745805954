import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./App.css";

import AppTopPane from "./components/AppTopPane";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import { handleSignOut } from "./utils/Auth";

import { userSlice } from "./store/userSlice";
import { interfaceSlice } from "./store/interfaceSlice";

import Profile from "./components/Profile";

import LandingPage from "./pages/LandingPage";
import PaperGPTAppPage from "./pages/PaperGPTAppPage";
import ProjectsPage from "./pages/ProjectsPage";
import AuthPage from "./pages/AuthPage";
import CreditPage from "./pages/CreditPage";
import ResendConfirmationEmail from "./ResendConfirmationEmail";
import AdminPanel from "./pages/AdminPanel";
import BlogPage from "./pages/BlogPage";
import BlogPostPage from "./pages/BlogPostPage";

import { getAllProjects, getAllPdfFiles, loadUser } from "./utils/Endpoints";

function App() {
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// variables ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const selectedPdfFiles = useSelector((state) => state.interface.selectedPdfFiles);

  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );
  const refetchGetAllPdfFiles = useSelector(
    (state) => state.interface.refetchGetAllPdfFiles
  );
  const refetchGetCredit = useSelector((state) => state.user.refetchGetCredit);
  const projects = useSelector((state) => state.user.projects);
  const credit = useSelector((state) => state.user.credit);

  const refetchGetAllProjects = useSelector(
    (state) => state.interface.refetchGetAllProjects
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const assignProjects = async () => {
      const result = await getAllProjects(user, accessToken);
      if (result) {
        dispatch(userSlice.actions.setProjects(result));
      } else if (result === "Not authorized") {
        navigate("/auth");
        handleSignOut();
      }
    };
    assignProjects();
  }, [refetchGetAllProjects, user, accessToken]);


  useEffect(() => {
    dispatch(interfaceSlice.actions.setTickedPdfFiles([]));
  }, [selectedProjectName]);


  useEffect(() => {
    const assignPdfFiles = async (request) => {
      if ([null, undefined, ""].includes(selectedProjectName)) {
        return;
      }

      const result = await getAllPdfFiles(user, accessToken, request);

    if (!result){
        dispatch(interfaceSlice.actions.setSelectedPdfFiles([]))
        return;
      }

    if (selectedPdfFiles !== result) {
        dispatch(interfaceSlice.actions.setSelectedPdfFiles(result));
      } else if (result === "Not authorized") {
        navigate("/auth");
        handleSignOut();
      } else if (result === "Project not found") {
        dispatch(userSlice.actions.setSelectedProjectName(""));
      }
    };
    const request = {
      project_name: selectedProjectName,
    };
    assignPdfFiles(request);
  }, [refetchGetAllPdfFiles, user, accessToken, selectedProjectName]);



  useEffect(() => {
    // Function to load access token from localStorage

    // TODO: accessToken and refreshToken should not be stored in localStorage
    const loadAccessToken = () => {
      
      const user = localStorage.getItem("user");
      dispatch(userSlice.actions.setUser(user));

      const userEmail = localStorage.getItem("userEmail");
      dispatch(userSlice.actions.setUserEmail(userEmail));

      const refreshToken = localStorage.getItem("refreshToken");
      dispatch(userSlice.actions.setRefreshToken(refreshToken));
      
      const token = localStorage.getItem("accessToken");
      dispatch(userSlice.actions.setAccessToken(token));
    };

    // Load access token when the component mounts
    loadAccessToken();

    // Add an event listener to detect changes in localStorage
    window.addEventListener("storage", loadAccessToken);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("storage", loadAccessToken);
    };
  }, []);

  useEffect(() => {

    if ([null, undefined, ""].includes(accessToken)) {
      return;
    }

    loadUser(accessToken).then((data) => {
      if (data) {
        dispatch(userSlice.actions.setIsPaidUser(data["is_paid_user"]));
      } else {
        dispatch(userSlice.actions.setIsPaidUser(false));
      }
    });
  }, [accessToken, user, credit]);


  return (
    <>

    <AppTopPane user={user}/>

    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/write-article" element={<PaperGPTAppPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/resend-confirmation-email" element={<ResendConfirmationEmail />} />
      <Route path="/profile" element={<Profile user={user} handleSignOut={handleSignOut} />} />
      <Route path="/projects" element={<ProjectsPage/>} />
      <Route path="/credit" element={<CreditPage />} />
      <Route path="/admin" element={<AdminPanel />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/blog/:id" element={<BlogPostPage />} />

      {/* Any other route will be redirected to the landing page */}
      <Route path="*" element={<Navigate to="/" />} />

    </Routes>
    
    </>
  );
}

export default App; 

