import { selectClasses } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  userEmail: "",
  credit: 0,
  accessToken: "",
  refreshToken: "",
  projects: [],
  selectedProjectName: localStorage.getItem("selectedProjectName") || "",
  checkEmail: false,
  isPaidUser: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload; // Set the user
    },

    setUserEmail: (state, action) => {
      state.userEmail = action.payload; // Set the user email
    },

    setCredit: (state, action) => {
      state.credit = action.payload; // Set the credit
    },


    setAccessToken: (state, action) => {
      state.accessToken = action.payload; // Set the access token
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload; // Set the refresh
    },

    setProjects: (state, action) => {
      state.projects = action.payload; // Set the projects
    },

    setSelectedProjectName: (state, action) => {
      localStorage.setItem("selectedProjectName", action.payload);
      state.selectedProjectName = action.payload;
    },

    setCheckEmail: (state, action) => {
      state.checkEmail = action.payload;
    },

    setIsPaidUser: (state, action) => {
      state.isPaidUser = action.payload;
    },

  },
});