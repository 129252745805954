import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function ShowToast(errorValue, toastType = "error") {

  if (toastType === "error") {
    toast.error(errorValue, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  } else {
    toast.success(errorValue, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
    }
  };