

import { Box, Typography } from '@mui/material';

export default function Post5_HowToWriteAFullArticle() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>

        PaperGPT takes care of the futile parts of writing a scientific paper. <br/>
        You can focus on the interesting part - discovering new knowledge. <br/><br/>

        PaperGPT can help with finding research gap, methodology, results, and discussion. <br/><br/>
        
        But finalizing it is your job. <br/><br/>

        Once you have these 3 sections, the rest of the paper will be written automatically. <br/><br/>

        <b>Step 1:</b> Upload the papers you have read. <br/>
        <b>Step 2:</b> Add the research gap, methodology, and results. <br/>
        <b>Step 3:</b> Click on the generate button of each section. <br/><br/>

        And that's it! You will get a scientific paper tailored to your research question, methodology, and results in seconds.

        <br/><br/>

        <b>Dependencies</b>
        <ul>
          <li>Research gap</li>
          <li>Methodology</li>
          <li>Results</li>
        </ul>

        <br/><br/>

        <b>Note:</b> The paper is written based on the research gap, methodology, and results.
        The more detailed information you provide, the better the paper will be.

      </Typography>


      <Typography variant="h2" gutterBottom>
        Example
      </Typography>

      <Typography variant="h4" gutterBottom>
        Step 1: Literature review
      </Typography>

      <Typography variant="h6" gutterBottom>
        Literature review it the first section that you need to write.
        Other parts of the paper is written based on the literature review.
      </Typography>

      <img
        src={"/posts/post5/image1.png"}
        alt="How to write the literature review"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>


      <Typography variant="h4" gutterBottom>
        Step 2: Introduction
      </Typography>

      <Typography variant="h6" gutterBottom>
        Introduction is the second section that you need to write.
        It is written based on the literature review, research gap, methodology, and results. <br/><br/>

        <b>Dependencies:</b>
        <ul>
          <li>Literature review</li>
          <li>Research gap</li>
          <li>Methodology</li>
          <li>Results</li>
        </ul>
      </Typography>

      <img
        src={"/posts/post5/image2.png"}
        alt="How to write the introduction"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h4" gutterBottom>
        Step 3: Abstract, conclusion, references, and title
      </Typography>


      <Typography variant="h6" gutterBottom>
        These sections are written based on the introduction, literature review, research gap, methodology, and results. <br/><br/>

        <b>Note:</b> The references section will not do the job of a reference manager.
        You need to add the references manually later. Expect many bugs and issues in this section. <br/><br/>

        <b>Dependencies:</b>
        <ul>
          <li>Introduction</li>
          <li>Literature review</li>
          <li>Research gap</li>
          <li>Methodology</li>
          <li>Results</li>
        </ul>
      </Typography>

      <img
        src={"/posts/post5/image3.png"}
        alt="How to write the abstract, conclusion, references, and title"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h4" gutterBottom>
        Step 4: Modify each section
      </Typography>

      <Typography variant="h6" gutterBottom>
        You can modify all of each section in the chat box, either with your prompts or one of the pre-defined prompts.
      </Typography>

      <img
        src={"/posts/post5/image4.png"}
        alt="How to modify each section"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h6" gutterBottom>
        You can also modify some parts of the paper by highlighting the text and writing your prompt in the box. <br/><br/>
 
        You directly assign the modified part to the section, or copy the modified part to the clipboard. <br/><br/>

        The modified sentences are shown in bold in the chat box.
      </Typography>

      <img
        src={"/posts/post5/image5.png"}
        alt="How to modify some parts of the paper"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h4" gutterBottom>
        Step 5: Pre-publication steps
      </Typography>

      <Typography variant="h6" gutterBottom>
        You can write highlights of the paper, or cover letter for the journal using the PaperGPT. <br/><br/>

        <b>Dependencies for highlights:</b>
        <ul>
          <li>Introduction</li>
          <li>Methodology</li>
          <li>Results</li>
          <li>Conclusion</li>
        </ul>
        
        <br/>

        <b>Dependencies for cover letter:</b>
        <ul>
          <li>Introduction</li>
          <li>Conclusion</li>
        </ul>

      </Typography>

      <br/><br/>

      <Typography variant="h4" gutterBottom>
        Step 6: Critically analyze your paper
      </Typography>

      <Typography variant="h6" gutterBottom>
        PaperGPT can serve as a critical reader for your paper. <br/><br/>

        It can help you find the flaws and strengths of your paper. It serves the purpose of peer review. <br/><br/>

        <b>Dependencies:</b>
        <ul>
          <li>Abstract</li>
          <li>Introduction</li>
          <li>Literature review</li>
          <li>Methodology</li>
          <li>Results</li>
          <li>Conclusion</li>
        </ul>

      </Typography>

      <img
        src={"/posts/post5/image6.png"}
        alt="How to critically analyze your paper"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h4" gutterBottom>
        Step 7: Download the paper
      </Typography>

      <Typography variant="h6" gutterBottom>
        You can download the paper in Microsoft Word format. There is no formatting at the moment.
      </Typography>

      <img
        src={"/posts/post5/image7.png"}
        alt="How to download the paper"
        style={{
          width: "100%",
        }}
      />


    </Box>
  );
}