

import { Box, Typography } from '@mui/material';

export default function Post1_HowToGenerateResearchIdea() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Stuck with generating research ideas? <br/>
        Use PaperGPT to generate research ideas in seconds. <br/><br/>

        <b>Step 1:</b> Upload the papers you have read. <br/>
        <b>Step 2:</b> Click on the "Research Gap" section. <br/>
        <b>Step 3:</b> Click on the "Identify the future research direction" button. <br/><br/>

        And that's it! You will get a list of research ideas in seconds.
      </Typography>

      <img
        src={"/posts/post1/image1.png"}
        alt="How to Generate Research Idea"
        style={{
          width: "100%",
        }}
      />

      <br/><br/><br/><br/>

      <Typography variant="h6" gutterBottom>

        You can simply copy the research idea to the clipboard by clicking on the "Copy" button.
        Or directly assign it to the research gap if the results are satisfactory.

      </Typography>

      <img
        src={"/posts/post1/image2.png"}
        alt="How to Copy Research Idea"
        style={{
          width: "100%",
        }}
      />
    </Box>
  );
}