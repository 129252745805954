

import { Box, Typography } from '@mui/material';

export default function Post6_HowToPeerReviewForJournal() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>

        Professors and academic editors have a tough job. They need to review many papers and make decisions based on them. <br/><br/>

        PaperGPT is not the final reviewer. It is your assistant. It can give you a very good starting point for your review. <br/><br/>
        
        But finalizing it is your job. <br/><br/>

        <b>Step 1:</b> Upload and select the paper. <br/>
        <b>Step 2:</b> Write the review. <br/>
        
        <br/><br/>

        And that's it! You will get a scientific review for your paper.

        <br/><br/>

        <b>Note:</b> You can only write the review for one paper at a time.

      </Typography>


      <img
        src={"/posts/post6/image1.png"}
        alt="How to write the peer review"
        style={{
          width: "100%",
        }}
      />


    </Box>
  );
}