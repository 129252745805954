import { createSlice } from "@reduxjs/toolkit";


const create_empty_article = () => {

  const empty_article = {
    title: "",
    lit_rev_one_by_one: "",
    lit_rev_analysis_table: "",
    lit_rev: "",
    introduction: "",
    conclusion: "",
    abstract: "",
    highlights: "",
    research_gap: "",
    methodology: "",
    results: "",
    cover_letter_to_editor: "",
    critical_analysis: "",
    reference_list: "",
  }

  return empty_article;
}

const create_empty_chat = () => {
  const chatHistory = {
    "userQuery": "",
    "withDocuments": [],
    "withComponents": {},
  };
  return chatHistory;
}


const initialState = {
  projectKey: null,
  chatKey: null,
  chatHistory: create_empty_chat(),
  article: create_empty_article(),
};

export const projectSlice = createSlice({
  name: "project",
  initialState,

  reducers: {
    setProjectKey: (state, action) => {
      if (action.payload.userEmail === null || action.payload.selectedProjectName === null ||
          action.payload.userEmail === "" || action.payload.selectedProjectName === "" ||
          action.payload.userEmail === undefined || action.payload.selectedProjectName === undefined
      ){
        return;
      }
      state.projectKey = `article-${action.payload.userEmail}-${action.payload.selectedProjectName}`

      // Get the article from local storage
      const article = localStorage.getItem(state.projectKey);
      if (article) {
        state.article = JSON.parse(article);
      }
    },

    setChatKey: (state, action) => {
      if (action.payload.userEmail === null || action.payload.selectedProjectName === null ||
          action.payload.userEmail === "" || action.payload.selectedProjectName === "" ||
          action.payload.userEmail === undefined || action.payload.selectedProjectName === undefined
      ){
        return;
      }
      state.chatKey = `chat-${action.payload.userEmail}-${action.payload.selectedProjectName}`

      // Get the chat history from local storage
      const chatHistory = localStorage.getItem(state.chatKey);
      if (chatHistory) {
        state.chatHistory = JSON.parse(chatHistory);
      }
    },

    setWholeChatHistory: (state, action) => {
      state.chatHistory = action.payload.chatHistory;
    },

    setWholeArticle: (state, action) => {
      state.article = action.payload.article;
    },

    setChatHistoryWithComponents: (state, action) => {
      state.chatHistory.withComponents[action.payload.sectionName] = action.payload.chatContent;
    },

    addToChatHistoryWithComponents: (state, action) => {
      if (state.chatHistory.withComponents[action.payload.sectionName] === undefined){
        state.chatHistory.withComponents[action.payload.sectionName] = [];
      }
      state.chatHistory.withComponents[action.payload.sectionName].push(action.payload.chatContent);
    },

    addToChatHistoryWithDocuments: (state, action) => {
      state.chatHistory.withDocuments.push(action.payload.chatContent);
    },

    setChatHistoryWithDocuments: (state, action) => {
      state.chatHistory.withDocuments.push(action.payload.chatContent);
    },

    setChatHistoryUserQuery: (state, action) => {
      state.chatHistory.userQuery = action.payload.userQuery;
    },

    setArticle: (state, action) => {
      state.article[action.payload.sectionName] = action.payload.sectionContent;
    },

    resetArticle: (state) => {
      localStorage.removeItem(state.projectKey);
      state.article = create_empty_article();
    },

    resetChatHistory: (state) => {
      localStorage.removeItem(state.chatKey);
      state.chatHistory = create_empty_chat();
    },

    deleteProject: (state) => {
      localStorage.removeItem(state.projectKey);
      localStorage.removeItem(state.chatKey);
      state.article = create_empty_article();
      state.chatHistory = create_empty_chat();
    },

  },
});


