import ShowToast from "../components/toast";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Button,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import "react-chat-elements/dist/main.css";
import { interfaceSlice } from "../store/interfaceSlice";
import { projectSlice } from "../store/projectSlice";

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CloseIcon from '@mui/icons-material/Close';

import SuggestionsForChatBox from "./ChatBoxSuggestions";


import { sectionNamesToSectionHeadings } from "../utils/ChatAndArticleFuncs";

import { fetchModifyComponents, fetchChatPdf } from "../utils/Endpoints";

export default function ChatBox ({}) {
  ///////////////////////////////////////////////////////////////////
  ///////////////////// states //////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  const dispatch = useDispatch();
  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const tickedPdfFiles = useSelector((state) => state.interface.tickedPdfFiles);
  const selectedTag = useSelector((state) => state.interface.selectedTag);

  const article = useSelector((state) => state.project.article);
  const chatHistory = useSelector((state) => state.project.chatHistory);

  const llmResponseLoading = useSelector((state) => state.interface.llmResponseLoading);
  const llmRespondingToChat = useSelector((state) => state.interface.llmRespondingToChat);
  const selectedPdfFiles = useSelector((state) => state.interface.selectedPdfFiles);
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const selectedProjectName = useSelector((state) => state.user.selectedProjectName);
  const highlightedText = useSelector((state) => state.interface.highlightedText);

  ///////////////////////////////////////////////////////////////////
  ///////////////////// functions ///////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  const handleSendMessageForComponentModification = async (user_query) => {
    
    if (!user && !accessToken) {return;}

    const component_text = article[selectedComponent];

    let request = {
      component_text: component_text,
      user_query: user_query,
    }

    if (chatHistory["withComponents"][selectedComponent] === undefined) {
      dispatch(projectSlice.actions.setChatHistoryWithComponents({
        sectionName: selectedComponent,
        chatContent: [],
      }));
    }

    dispatch(projectSlice.actions.addToChatHistoryWithComponents({
      sectionName: selectedComponent,
      chatContent: {
        position: "right",
        type: "text",
        text: user_query,
        date: new Date().toISOString(),
      },
    }));


    try {
      const response = await fetchModifyComponents(user, accessToken, request);
      dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
      dispatch(interfaceSlice.actions.setLlmResponseLoading(false));

      if (response === "Not enough credits.") {
        ShowToast("Not enough credits.");
        return;
      }

      if (response === null || response === undefined) {
        ShowToast("Error in fetching response from the server.");
        return;
      }

      dispatch(projectSlice.actions.setChatHistoryUserQuery({userQuery: ""}));
      dispatch(projectSlice.actions.addToChatHistoryWithComponents({
        sectionName: selectedComponent,
        chatContent: {
          position: "left",
        type: "text",
        text: response,
          date: new Date().toISOString(),
        },
      }));
      dispatch(interfaceSlice.actions.addNEdits());
      dispatch(interfaceSlice.actions.setRefetchGetCredit());

    } catch (error) {
        console.error('Error:', error);
    }
    return;
  }


  const handleSendMessageForModifyingHighlightedText = async (user_query) => {

    if (!user && !accessToken) {return;}


    if (chatHistory["withComponents"][selectedComponent] === undefined) {
      dispatch(projectSlice.actions.setChatHistoryWithComponents({
        sectionName: selectedComponent,
        chatContent: [],
      }));
    }

    let request = {
      component_text: highlightedText,
      user_query: user_query,
    }

    dispatch(projectSlice.actions.setChatHistoryUserQuery({userQuery: ""}));
    dispatch(projectSlice.actions.addToChatHistoryWithComponents({
      sectionName: selectedComponent,
      chatContent: {
        position: "right",
        type: "text",
        text: highlightedText + " \n \n " + user_query,
        date: new Date().toISOString(),
      },
    }));

    dispatch(interfaceSlice.actions.setLlmResponseLoading(true));
    dispatch(interfaceSlice.actions.addNEdits());

    try {

      const response_text = await fetchModifyComponents(user, accessToken, request);

      if (response_text === "Not enough credits") {
        ShowToast("Not enough credits. Please upgrade.");
        return;
      }

      if (response_text === null || response_text === undefined) {
        ShowToast("Error in fetching response from the server.");
        return;
      }

      // Replace the highlighted text with the response text
      dispatch(projectSlice.actions.addToChatHistoryWithComponents({
        sectionName: selectedComponent,
        chatContent: {
          position: "left",
          type: "text",
          text: response_text,
          date: new Date().toISOString(),
          original_text: highlightedText,
        },
      }));
      dispatch(interfaceSlice.actions.addNEdits());
      dispatch(interfaceSlice.actions.setRefetchGetCredit());
      dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
      dispatch(interfaceSlice.actions.setLlmResponseLoading(false));

    } catch (error) {
        console.error('Error:', error);
    }
    return;
    
  }


  const handleSendMessageForAskingFromDocuments = async (user_query) => {
    
    if (!user && !accessToken) {return;}
    if (selectedPdfFiles.length === 0) {return;}
    if (tickedPdfFiles.length === 0) {return;}

    const message = {
      position: "right",
      type: "text",
      text: user_query,
      date: new Date().toISOString(),
    }

    dispatch(projectSlice.actions.addToChatHistoryWithDocuments({
      chatContent: message,
    }));
    dispatch(projectSlice.actions.setChatHistoryUserQuery({userQuery: ""}));


    // Get the last 3 messages from the chat history and concat them as a single string
    // This is the chat history that will be used as the context
    let chat_history = "";
    const chat_history_length = chatHistory["withDocuments"].length;
    const start_index = Math.max(0, chat_history_length - 4);
    for (let i = start_index; i < chat_history_length; i++) {
      chat_history += chatHistory["withDocuments"][i].text + " ";
    }

    let request = {
      file_names: tickedPdfFiles,
      user_query: user_query,
      chat_history: chat_history,
      project_name: selectedProjectName,
    };

    const response = await fetchChatPdf(user, accessToken, request);

    if (response === "Not enough credits.") {
      ShowToast("Not enough credits.");
      return;
    }

    if (response === null || response === undefined) {
      dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
      ShowToast("Error in fetching response from the server.");
      return;
    }

    dispatch(projectSlice.actions.addToChatHistoryWithDocuments({
      chatContent: {
        position: "left",
        type: "text",
        text: response,
        date: new Date().toISOString(),
      },
    }));
    dispatch(interfaceSlice.actions.setLlmRespondingToChat(false));
    dispatch(interfaceSlice.actions.addNEdits());
    dispatch(interfaceSlice.actions.setRefetchGetCredit());

  };
  
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  if (selectedTag === "lit_rev_analysis_table_tag") {
    return null;
  }

  const isChatDisabled = (
    (llmResponseLoading || llmRespondingToChat) ||
    (selectedComponent !== null && article[selectedComponent] === null) ||
    (selectedComponent === "lit_rev_analysis_table")
  )


  return (
    <Grid item xs={3}>
    <Box
      sx={{
        height: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        borderRadius: 1,
        marginTop: 1,
        marginRight: 1,
        border: "1px solid #ccc",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        pointerEvents: isChatDisabled ? "none" : "auto",
      }}
    >
      

      {selectedComponent === null && (
        <Box
          sx={{
            color: "gray",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            paddingLeft: 2,
            paddingBottom: 1,
          }}
        >
          <Typography
            sx={{
              width: "50%",
              fontSize: 14,
            }}
          >
          {tickedPdfFiles.length} documents selected
        </Typography>
        </Box>
      )}
      {["", null, undefined].includes(highlightedText) && (
        <SuggestionsForChatBox
        handleSendMessageForAskingFromDocuments={handleSendMessageForAskingFromDocuments}
        handleSendMessageForComponentModification={handleSendMessageForComponentModification}
        />
      )}

      <MessagesList />


      {selectedComponent !== "lit_rev_analysis_table" && (
        <ChatWithHighlightedText
          sectionName={selectedComponent}
        />
      )}

      <UserQueryInput
        handleSendMessageForAskingFromDocuments={handleSendMessageForAskingFromDocuments}
        handleSendMessageForComponentModification={handleSendMessageForComponentModification}
        handleSendMessageForModifyingHighlightedText={handleSendMessageForModifyingHighlightedText}
      />

    </Box>
    </Grid>
  );
};


// ----------------------------------------------------------------
// ------------------- Helper Components --------------------------
// ----------------------------------------------------------------

const MessagesList = ({}) => {

  const chatHistory = useSelector((state) => state.project.chatHistory);
  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const tickedPdfFiles = useSelector((state) => state.interface.tickedPdfFiles);
  const llmResponseLoading = useSelector((state) => state.interface.llmResponseLoading);
  const llmRespondingToChat = useSelector((state) => state.interface.llmRespondingToChat);


  const messageListRef = useRef(null);

  const scrollToBottom = () => {
    if (messageListRef && messageListRef) {
      messageListRef.scrollTop = messageListRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [llmRespondingToChat, selectedComponent, llmResponseLoading]);


  ////////////////////////////////
  // Once rendered, we need to scroll to the bottom
  useEffect(() => {
    scrollToBottom();
  }, []);

  const generateMessage = () => {

    if (selectedComponent === null) {
      return (
        chatHistory['withDocuments'].map((message) => ({
          position: message.position,
          type: message.type,
          text: message.text,
          date: new Date(message.date),
          title: message.position === 'right' ? '' : 'PaperGPT',
          original_text: message.original_text ? message.original_text : null,
        }))
      )
    } else {

      if (chatHistory['withComponents'][selectedComponent] === undefined) {
        return [];
      }

      return (
        chatHistory['withComponents'][selectedComponent].map((message) => ({
          position: message.position,
          type: message.type,
          text: message.text,
          date: new Date(message.date),
          title: message.position === 'right' ? '' : 'PaperGPT',
          original_text: message.original_text ? message.original_text : null,
        }))
      )
    }
  }

  const placeholder = () => {

    if (selectedComponent === "lit_rev_analysis_table") {
      return "Chat with literature review analysis table is disabled."
    }

    if (selectedComponent === null && tickedPdfFiles.length !== 0) {
      return "Ask anything from your documents..."
    }

    if (selectedComponent === null && tickedPdfFiles.length === 0) {
      return "Select a file or section to start"
    }

    if (selectedComponent === null && tickedPdfFiles.length > 0) {
      return "Chat with your literature"
    }

    if (selectedComponent !== null) {
      return `Modify ${sectionNamesToSectionHeadings[selectedComponent]}`
    }
  }

  const isDisabled = (
    llmResponseLoading || llmRespondingToChat || (tickedPdfFiles.length === 0 && selectedComponent === null)
  )

  return (

    <Box
        sx={{
          width: "100%",
          height: "calc(95% - 60px)",
          overflowY: "auto",
          overflowX: "hidden", // Prevent horizontal overflow
          marginBottom: 2,
          scrollBehavior: "smooth",
        }}
      >

        {(generateMessage().length === 0 || isDisabled) &&

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <Box
              width="60%"
              height="40%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              sx={{
                backgroundColor: "#F8F8F8",
                border: "3px dotted #A9A9A9",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >

              <Typography
                sx={{
                  justifyContent:"center",
                  color: "#A9A9A9",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                Looks so empty!
                <br/>
                {placeholder()}

              </Typography>

              <HistoryEduIcon
                sx={{
                  fontSize: 80,
                  marginTop: 2,
                  color: "#E8E8E8"
                }}
              />

            </Box>
          </Box>
        }


        {!isDisabled && (
        <CustomizedMessagesInChatBox
          messages={generateMessage()}
        />
        )}


        <Box ref={messageListRef}></Box>

    </Box>
  )
}


const UserQueryInput = ({
  handleSendMessageForAskingFromDocuments,
  handleSendMessageForComponentModification,
  handleSendMessageForModifyingHighlightedText,
}) =>{

  const chatKey = useSelector((state) => state.user.selectedChatKey);
  const chatHistory = useSelector((state) => state.project.chatHistory);
  const article = useSelector((state) => state.project.article);
  const highlightedText = useSelector((state) => state.interface.highlightedText);
  const dispatch = useDispatch();
  const nEdits = useSelector((state) => state.interface.nEdits);
  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const tickedPdfFiles = useSelector((state) => state.interface.tickedPdfFiles);
  const [value, setValue] = useState(()=>{
    if (localStorage.getItem(chatKey) !== null) {
      return JSON.parse(localStorage.getItem(chatKey))["userQuery"]
    }
    return ""
  })

  const llmResponseLoading = useSelector((state) => state.interface.llmResponseLoading);
  const llmRespondingToChat = useSelector((state) => state.interface.llmRespondingToChat);

  if (localStorage.getItem(chatKey) !== null) {
    if (Object.keys(chatHistory).length === 0){
      chatHistory = JSON.parse(localStorage.getItem(chatKey))
    }
  }

  useEffect(() => {
    dispatch(projectSlice.actions.setChatHistoryUserQuery({userQuery: value}));
  }, [value]);

  useEffect(() => {
    setValue(chatHistory["userQuery"]);
  }, [nEdits]);

  // If user hits "Enter", we send the message
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSendMessageChatting();
    }
  };
  
  const handleInputChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(event.target.value);
  };

  const handleSendMessageChatting = () => {

    if (value === "") {
      ShowToast("Please enter a message to send.");
      return;
    }

    if (tickedPdfFiles.length === 0 && selectedComponent === null) {
      ShowToast("Please select at least one file to ask from.");
      return;
    }

    if (selectedComponent !== null && article[selectedComponent] === null) {
      ShowToast("The selected section is empty, you cannot modify an empty section.");
      return;
    }

    dispatch(interfaceSlice.actions.setLlmRespondingToChat(true));

    if (selectedComponent === null) {
      handleSendMessageForAskingFromDocuments(value);
    } else {
      if (highlightedText !== "") { 
        handleSendMessageForModifyingHighlightedText(value);
      } else {
        handleSendMessageForComponentModification(value);
      }
    }
    setValue("");
    dispatch(interfaceSlice.actions.setRefetchGetCredit());
  }

  const generatePlaceholder = () => {
    if (selectedComponent !== null) {
      return `Modify ${sectionNamesToSectionHeadings[selectedComponent]}...`
    } else {
      if (tickedPdfFiles.length === 0) {
        return "Select a file or section to start."
      } else {
        return "Ask anything from your documents..."
      }
    }
  }

  const isDisabled = (
    llmResponseLoading || llmRespondingToChat || (tickedPdfFiles.length === 0 && selectedComponent === null)
  )

  return (

    <Box
      sx={{
        width: "100%",
        height: "5%",
        margin: "0 auto",
        display: "flex",
        alignItems: "flex-end",
      }}
    >

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          border: "1px solid #ccc",
          borderRadius: 10,
          width: "100%",
          backgroundColor: "#fff",
        }}
      
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {selectedComponent !== null && (
            <Typography
              sx={{
                paddingLeft: 1,
              }}
            >
              ✨
            </Typography>
          )}
          
          {selectedComponent === null && (
            <Typography
              sx={{
                paddingLeft: 1,
              }}
            >
              {tickedPdfFiles.length > 0 ? "🟢" : "⚪"}
            </Typography>
            )
          }
        </Box>


        <TextField
          placeholder={generatePlaceholder()}
          multiline
          fullWidth
          disabled={isDisabled}
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          InputProps={{
            sx: {
              fontSize: 15, // Set the font size here
              '& textarea': {
                minHeight: '20px', // Set a minimum height
              }
            },
          }}


          sx={{
            marginRight: 1,
            '& .MuiOutlinedInput-root': {
                border: 'none', // Remove the border
                '& fieldset': {
                    border: 'none', // Remove the fieldset border
                },
                padding: '6px 6px', // Reduce padding
                lineHeight: '1.5', // Adjust line height for vertical centering
            },
            '& input': {
                fontSize: '13 px', // Smaller font size
                padding: '0', // Remove additional padding
                display: 'flex',
                alignItems: 'center', // Align items center vertically
                height: '100%', // Ensure the input takes full height
            },
            '& textarea': {
                display: 'flex',
                alignItems: 'center', // Align items center vertically for multiline
            },
          }}

        />

        <Tooltip title="Reset Chat">
          <span>
            <Button
                sx={{
                minWidth: '0', // Prevent minimum width
                minHeight: '0', // Prevent minimum height
                borderRadius: '4px', // Adjust radius if needed
                fontSize: '0.75rem', // Smaller font size
                marginRight: 0,
                color: '#909090',
                '&:hover': {
                  backgroundColor: 'transparent',
                  transform: 'scale(1.1)'
                }
              }}
              disabled={isDisabled}
              onClick={() => dispatch(projectSlice.actions.resetChatHistory())}
            >
              <ReplayIcon/>
            </Button>
          </span>
        </Tooltip>

        <Tooltip title="Send Message">
          <span>
            <Button
                sx={{
                paddingRight: 2,
                minWidth: '0', // Prevent minimum width
                minHeight: '0', // Prevent minimum height
                borderRadius: '4px', // Adjust radius if needed
                fontSize: '0.75rem', // Smaller font size
                marginRight: 0,
                color: '#909090',
                '&:hover': {
                  backgroundColor: 'transparent',
                  transform: 'scale(1.1)'
                }
              }}
              disabled={isDisabled}
              onClick={handleSendMessageChatting}
            >
              <SendOutlinedIcon/>
            </Button>
          </span>
        </Tooltip>
      </Box>

    </Box>
  
      
  )
}


// Main component
const CustomizedMessagesInChatBox = ({ messages}) => {
  
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginLeft: 0,
      marginRight: 0,
      borderRadius: 10,
      width: "100%",
    }}>

      {messages.map(message => (
        <Message
          key={message.date + message.text.slice(0, 10)}
          message={message}
        />
      ))}
    </div>
  );
};


const Message = ({message}) => {

  const dispatch = useDispatch();

  const selectedComponent = useSelector((state) => state.interface.selectedComponent);
  const nEdits = useSelector((state) => state.interface.nEdits);
  const article = useSelector((state) => state.project.article);

  const chatHistory = useSelector((state) => state.project.chatHistory);

  const handleClickAssign = (e) => {
    e.preventDefault();
    e.stopPropagation();


    if (message.original_text === null) {
      dispatch(projectSlice.actions.setArticle({
        sectionName: selectedComponent,
        sectionContent: message.text.replace(/\*\*(.+)\*\*/g, "$1"),
      }));
    } else {
      let updated_text = article[selectedComponent].replace(message.original_text, message.text);
      dispatch(projectSlice.actions.setArticle({
        sectionName: selectedComponent,
        sectionContent: updated_text,
      }));
      dispatch(interfaceSlice.actions.setHighlightedText(""));
    }

    
    dispatch(interfaceSlice.actions.addNEdits());
  }

  const handleClickCopyToClipboard = (e) => {
    e.preventDefault()
    e.stopPropagation()
    navigator.clipboard.writeText(message)
    localStorage.setItem("ChatClipboard", message.text)
    dispatch(interfaceSlice.actions.addNEdits())
  }

  const copyButtonTitle = localStorage.getItem("ChatClipboard") === message.text
    ? "Copied"
    : "Copy"

  const assignTitle = () => {
    if (message.original_text === null) {
    } else {
      if (article[selectedComponent].includes(message.original_text)) {
        return "Update"
      } else {
        return "The original text is modified. Assign cannot be done."
      }
    }
  }

  return (
    <Box
      sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '5px',
      paddingLeft: '10px',
      borderRadius: '10px',
      backgroundColor: message.position === 'right' ? '' : '#F8F8F8',
      marginLeft: message.position === 'right' ? 'auto' : '0',
      marginRight: message.position === 'right' ? '0' : 'auto',
      border: message.position === 'right' ? '1px solid #ccc': '1px solid black',
      marginBottom: '10px',
      width: '80%',
      fontSize: '13px',
      boxShadow: 1,
    }}
    >
      
      <Typography
        sx={{
          fontSize: 10,
          color: "gray",
        }}
      >
        {message.position === 'right' ? 'You' : 'PaperGPT'}
      </Typography>

      <ReactMarkdown>
        {message.text}
      </ReactMarkdown>

      {(message.position !== 'right' && selectedComponent !== null) && (
        <Box>

          {assignTitle() !== "The original text is modified. Assign cannot be done." && (
            <Tooltip title={assignTitle()}>
              <ArrowCircleLeftIcon
                sx={{
                  "color": "lightgray",
                  "&:hover": 
                    {
                      color: "darkgray",
                      cursor: "pointer",
                    },
              }}
                onClick={handleClickAssign}
              >
              </ArrowCircleLeftIcon>
            </Tooltip>
          )}
          

          <Tooltip title={copyButtonTitle}>
            <ContentCopyIcon
              sx={{
                "color": "lightgray",
                "marginLeft": 1,
                "&:hover": 
                  {
                    color: "darkgray",
                    cursor: "pointer"
                  },
              }}
              disabled={copyButtonTitle==="Copied"}
              onClick={handleClickCopyToClipboard}
            >
            </ContentCopyIcon>
            
          </Tooltip>
          

        </Box>
      )}
      
    </Box>
  );
};


const ChatWithHighlightedText = ({sectionName}) => {

  const dispatch = useDispatch();
  const highlightedText = useSelector((state) => state.interface.highlightedText);
  const selectedComponent = useSelector((state) => state.interface.selectedComponent);

  if (selectedComponent !== sectionName || highlightedText === "") {
    return null;
  }

  return (

    <Box
      sx={{
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        marginLeft: 2,
      }}
      id={sectionName + "-chatWithHighlight-main-container"}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >

      {selectedComponent === sectionName &&

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: 1,
            }}
            id={sectionName + "-chatWithHighlight-sub-container"}
          >
            <Typography
              sx={{
                fontSize: 12,
                color: "blue",
                fontWeight: "bold",
              }}
              id={sectionName + "-chatWithHighlight-highlighted-text-title"}
            >
              Highlighted Text:
            </Typography>
          
            <CloseIcon
              sx={{
                color: "#909090",
                "&:hover": {
                  color: "darkgray",
                  cursor: "pointer",
                },
                fontSize: 14,
              }}
              onClick={()=> (dispatch(interfaceSlice.actions.setHighlightedText({
                "text": "",
                "sectionName": null,
              })))}
            >
            </CloseIcon>

          </Box>

            <Box
              sx={{
                width: "100%",
                marginBottom: 1,
              }}
              id={sectionName + "-chatWithHighlight-highlighted-text-container"}
            >
              <Typography
                sx={{
                  fontSize: 11,
                  color: "#909090",
                }}
              id={sectionName + "-chatWithHighlight-highlighted-text"}
            >
              {highlightedText}
            </Typography>
          </Box>

        </Box>
      }

    </Box>
  )
}
