import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { blogPosts } from '../posts/blogPosts';

export default function BlogPage() {
  

  return (
    <Grid 
      container
      spacing={0}
      direction="row"
      sx={{
        width: "100%",
        marginTop: "50px",
        minHeight: "1000px",
        height: "100%",
      }}
      padding={0}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minHeight: "980px !important",
          marginBottom: "20px",
        }}
      >
        <Grid item xs={12}>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              height: "100%",
              borderRadius: 1,
              marginTop: 1,
              marginLeft: 1,
              marginRight: 1,
              marginBottom: 1,
              border: "1px solid #ccc",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              overflowY: "auto",
            }}
          >

            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                textAlign: 'center',
                mb: 4,
                fontWeight: 'bold',
                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Start Here <br/>
              Learn how to use PaperGPT
            </Typography>

            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: "60%",
              }}
            >

              {blogPosts.map((post) => (
                <BlogPostCard
                  key={post.id}
                  post={post}
                />
              ))}

            </Box>


          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};


function BlogPostCard({post}) {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/${post.id}`);
  };

  return (
    <Box
      sx={{
        margin: 1,
        border: "1px solid #ccc",
        borderRadius: 1,
        boxShadow: 2,
        padding: 2,
        "&:hover": {
          boxShadow: 5,
          cursor: "pointer",
        },
      }}
      onClick={handleClick}
    >

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {post.title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          {post.date}
        </Typography>

      </Box>

      <Box>
        <Typography variant="body1" color="textSecondary">
          {post.excerpt}
        </Typography>
      </Box>

    </Box>

  );
}