

import {
  Box,
  Typography
} from "@mui/material";

import {interfaceSlice} from "../store/interfaceSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import CircularProgress from '@mui/material/CircularProgress';

export default function ProgressComponent() {

  const dispatch = useDispatch();

  const llmRespondingToChat = useSelector(
    (state) => state.interface.llmRespondingToChat
  );
  const llmResponseLoading = useSelector(
    (state) => state.interface.llmResponseLoading
  );

  const uploadingFiles = useSelector(
    (state) => state.interface.uploadingFiles
  );

  const deletingFiles = useSelector(
    (state) => state.interface.deletingFiles
  );

  const notificationMessage = useSelector(
    (state) => state.interface.notificationMessage
  );

  useEffect(() => {
    if (uploadingFiles || deletingFiles || llmRespondingToChat || llmResponseLoading || notificationMessage) {
      return;
    }
  }, [uploadingFiles, deletingFiles, llmRespondingToChat, llmResponseLoading, notificationMessage]);

  // If not any of the above, then don't show the progress component
  if (!(uploadingFiles || deletingFiles || llmRespondingToChat || llmResponseLoading)) {
    return null;
  }

  return (
    <Box sx={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
    }}>
      
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          marginBottom: "30px",
          backgroundColor: "lightgray",
          borderRadius: "10px",
          paddingLeft: 1,
          paddingRight: 1,
          opacity: 1,
          height: "10%",
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            color: "black",
            opacity: "1 !important",
          }}
        >
          {notificationMessage}
        </Typography>

        <CircularProgress
          sx={{
            marginTop: 1,
            opacity: "1 !important",
          }}
        />

      </Box>
    </Box>
  );
}