import { signOut } from "firebase/auth"
import { auth } from "./sdk";


export const handleSignOut = async () => {
  try {
    // Clear local storage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("selectedProjectName");

    await signOut(auth);
    window.location.reload();

  } catch (error) {
    console.error("Error signing out: ", error);
    // alert(`Error signing out: ${error.message}`);
  }
};
