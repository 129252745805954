import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TextField } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { Modal, Typography } from "@mui/material";
import Button from "@mui/material/Button";

import { interfaceSlice } from "../store/interfaceSlice";
import { userSlice } from "../store/userSlice";
import Profile from "./Profile";

import { handleRenameProject, getUserCredit } from "../utils/Endpoints";
import CreditToDollarCalculator from "../utils/CreditToDollarCalculator";
export default function AppTopPane({ user }) {
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// variables ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  const accessToken = useSelector((state) => state.user.accessToken);
  const navigate = useNavigate();
  const profileModalOpen = useSelector((state) => state.interface.profileModalOpen);
  const dispatch = useDispatch();
  const whichPage = useSelector((state) => state.interface.whichPage);
  const isPaidUser = useSelector((state) => state.user.isPaidUser);
  const refetchGetCredit = useSelector((state) => state.interface.refetchGetCredit);

  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );

  const userCredits = useSelector((state) => state.user.credit);

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ///////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const assignCredit = async () => {
      const credit = await getUserCredit(user, accessToken, userSlice);
      if (credit) {
        dispatch(userSlice.actions.setCredit(credit));
      }
    };
    assignCredit();
  }, [user, accessToken, refetchGetCredit]);

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// functions ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  const handleMenuClose = () => {
    dispatch(interfaceSlice.actions.setProfileModalOpen(false));
  };

  const handleClickProjectsIcon = () => {
    dispatch(interfaceSlice.actions.reset());
    dispatch(userSlice.actions.setSelectedProjectName(""));
    navigate("/projects");
  }

  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////// useEffects ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Box sx={{
        height: "0px",
        display: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        border: "none",
        }}
      >
        <AppBar
          position="static"
          sx={{
            backgroundColor: "transparent",
            boxShadow: 0,
          }}
        >
          <Toolbar sx={{ paddingLeft: "10px !important" }}>

          <Box sx={{ display: { xs: "none", md: "flex" }, marginRight: 2 }}>
              <Tooltip title="Home Page">
                <Button
                  onClick={() => navigate("/")}
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    minWidth: 0,
                    padding: 0,
                    marginRight: 1,
                    marginLeft: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "none",
                    textTransform: "none",
                  }}
                >
                  <Typography variant="h6" sx={{
                    fontFamily: "Hoefler Text",
                    color: "black",
                    fontSize: 20,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}>
                    PaperGPT
                  </Typography>

                </Button>
              </Tooltip>

            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "black",
                }}
              >
                {
                  (whichPage === "write-article") &&
                  <ProjectNameBox/>
                }
              </Typography>
              
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "none", md: "flex" } }}>

              <TopPaneButton
                handleClick={() => navigate("/blog")}
                title="Blog"
              >
                Blog
              </TopPaneButton>

              {(user || accessToken) &&
                <TopPaneButton
                  handleClick={() => navigate("/credit")}
                  title= "Credits"
                >
                  {isPaidUser? "Premium Account" : "Free Account" } | Current: ${CreditToDollarCalculator(userCredits).toLocaleString()}
                </TopPaneButton>
              }

              {(user || accessToken) &&
                <TopPaneButton
                  handleClick={handleClickProjectsIcon}
                  title="Projects"
                >
                  Projects
                </TopPaneButton>
              }


              {user || accessToken ? (
                <TopPaneButton
                  handleClick={() => dispatch(interfaceSlice.actions.setProfileModalOpen(true))}
                  title="Account"
                >
                  Account
                </TopPaneButton>
              ) : (
                <TopPaneButton
                  handleClick={() => navigate("/auth")}
                  title="Sign In"
                >
                  Sign In
                </TopPaneButton>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Modal open={profileModalOpen} onClose={handleMenuClose}>
        <>
          <Profile handleClose={handleMenuClose} />
        </>
      </Modal>
    </>
  );
}


function TopPaneButton({
  handleClick,
  title,
  children
}){


  return (
    <Tooltip title={title}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "transparent",
          color: "#fff",
          "&:hover": {
            backgroundColor: "transparent",
          },
          textTransform: "none",
          minWidth: 0,
          padding: 0,
          height: "30px",
          marginRight: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 1,
        }}
        onClick={handleClick}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: 12,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          {children}
        </Typography>
      </Button>
    </Tooltip>
  )
}


const ProjectNameBox = ({}) => {

  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.accessToken);
  const projects = useSelector((state) => state.user.projects);
  const selectedProjectName = useSelector(
    (state) => state.user.selectedProjectName
  );

  // Iterate over projects and find the project with the selectedProjectName
  const selectedProject = projects.find((project) => project.name === selectedProjectName);

  const [name, setName] = useState(() => {return selectedProjectName});
  const [ifNameChanged, setIfNameChanged] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleStartEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleChange = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
      return;
    }
    setName(event.target.value);
    setIfNameChanged(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const newProjectName = event.target.value;
      handleSaveProjectName(newProjectName);
      setIfNameChanged(false);
    }
    event.stopPropagation();
  };

  const handleBlur = (event) => {
    // Save on blur too
    if (ifNameChanged) {
      handleSaveProjectName(name);
      setIsEditing(false);
      setIfNameChanged(false);
      event.target.blur();
    }
  }

  const handleSaveProjectName = (newProjectName) => {

    if (!ifNameChanged) {
      setIsEditing(false);
      return;
    }

    if (newProjectName === selectedProjectName) {
      setIsEditing(false);
      return;
    }

    const pk = selectedProject.id;
    const result = handleRenameProject(dispatch, newProjectName, accessToken, userSlice, pk);
    
    setIsEditing(false);
    if (!result) {
      return;
    }
    // In the projects array, update the name of the project with the selectedProjectName
    const updatedProjects = projects.map((project) => {
      if (project.name === selectedProjectName) {
        return { ...project, name: newProjectName };
      }
      return project;
    });
    dispatch(userSlice.actions.setProjects(updatedProjects));
    setIsEditing(false);
    setIfNameChanged(false);
  }

  const nameToShow = name.length > 25 ? name.substring(0, 25) + "..." : name
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Tooltip title="Rename">
          <TextField
            value={name}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onClick={handleStartEdit}
            onBlur={handleBlur} // Save on blur too
            autoFocus
            variant="outlined"
            sx={{
              border: isEditing ? "1px solid #909090" : "none",
              borderRadius: 1,
              "& .MuiOutlinedInput-root": {
                height: "25px",
                "& input": {
                  padding: "0", // Remove default input padding
                  height: "25px", // Match input height
                  fontSize: "14px", // Optional: adjust font size if needed
                },
                "& fieldset": {
                  border: "none",
                },
                "&:hover fieldset": {
                  border: "none",
                },
                "&.Mui-focused fieldset": {
                  border: "none",
                },
              },
            }}
          />
        </Tooltip>
      
      </Box>

    </Box>

    
  );

}