import React, { useState } from "react";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userSlice } from "../store/userSlice";


const CheckYourEmail = ({ email }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkEmail = useSelector((state) => state.user.checkEmail);

  const handleCloseModal = () => {
    dispatch(userSlice.actions.setCheckEmail(false));
  };

  const handleBackToLogin = () => {
    navigate("/auth");
    dispatch(userSlice.actions.setCheckEmail(false));
  };

  return (
    <Modal
      open={checkEmail}
      onClose={handleCloseModal}
      aria-labelledby="check-your-email-title"
      aria-describedby="check-your-email-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="check-your-email-title" variant="h4" gutterBottom>
          Check Your Email
        </Typography>
        <Typography
          id="check-your-email-description"
          variant="body1"
          gutterBottom
        >
          We have sent a confirmation email to your address{" "}
          <Typography component="span" fontWeight="bold">
            {email}
          </Typography>
          . Please check your email and follow the instructions to complete your
          registration.
        </Typography>
        {/* <Button
          variant="outlined"
          color="secondary"
          sx={{ mt: 2, ml: 2 }}
          onClick={handleBackToLogin}
        >
          Back to Login
        </Button> */}
      </Box>
    </Modal>
  );
};

export default CheckYourEmail;
