import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  llmResponseLoading: false,
  llmRespondingToChat: false,
  selectedPdfFiles: [],
  tickedPdfFiles: [],
  profileModalOpen: false,
  whichPage: null,
  refetchGetAllPdfFiles: false,
  refetchGetAllProjects: false,
  refetchGetCredit: false,
  nEdits: 0,
  highlightedText: "",
  selectedComponent: null,
  selectedTab: "article_tab",
  selectedTag: "all_tag",
  whichSectionGenerating: null,
  uploadingFiles: false,
  deletingFiles: false,
  notificationMessage: "",
};


export const interfaceSlice = createSlice({
  name: "interface",
  initialState: initialState,
  reducers: {

    addNEdits: (state) => {
      state.nEdits += 1;
    },

    setWhichSectionGenerating: (state, action) => {
      state.whichSectionGenerating = action.payload;
    },

    setLlmResponseLoading: (state, action) => {
      if (action.payload) {
        state.notificationMessage = "PaperGPT is generating...";
      } else {
        state.notificationMessage = "";
      }
      state.llmResponseLoading = action.payload;
    },
    setLlmRespondingToChat: (state, action) => {
      if (action.payload) {
        state.notificationMessage = "PaperGPT is responding...";
      } else {
        state.notificationMessage = "";
      }
      state.llmRespondingToChat = action.payload;
    },

    setProfileModalOpen: (state, action) => {
      state.profileModalOpen = action.payload;
    },

    setWhichPage: (state, action) => {
      state.whichPage = action.payload;
    },

    setSelectedPdfFiles: (state, action) => {
      state.selectedPdfFiles = action.payload;
    },
  
    setRefetchGetAllPdfFiles: (state) => {
      state.refetchGetAllPdfFiles = !state.refetchGetAllPdfFiles;
    },

    setRefetchGetAllProjects: (state, action) => {
      state.refetchGetAllProjects = action.payload;
    },

    setTickedPdfFiles: (state, action) => {
      state.tickedPdfFiles = action.payload;
      state.highlightedText = "";
    },
    
    addTickedPdfFilesName: (state, action) => {
      state.tickedPdfFiles.push(action.payload);
      state.highlightedText = "";
    },

    removeTickedPdfFilesName: (state, action) => {
      state.tickedPdfFiles = state.tickedPdfFiles.filter(
        (fileName) => fileName !== action.payload
      );
      state.highlightedText = "";
    },

    resetTickedPdfFiles: (state) => {
      state.tickedPdfFiles = [];
    },

    setHighlightedText: (state, action) => {
      state.highlightedText = action.payload.text;
      if (action.payload.sectionName !== null){
        state.selectedComponent = action.payload.sectionName;
      }
    },

    setRefetchGetCredit: (state) => {
      state.refetchGetCredit = !state.refetchGetCredit;
    },

    setSelectedProjectName: (state, action) => {
      state.selectedProjectName = action.payload; // Set the selected project name
      localStorage.setItem("selectedProjectName", action.payload);
      state.highlightedText = "";
    },

    setSelectedComponent: (state, action) => {
      if (action.payload === state.selectedComponent) {
        state.selectedComponent = null;
      } else {
        state.selectedComponent = action.payload; // Set the selected component
      }
      state.highlightedText = "";
    },

    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload; // Set the selected tab
      state.highlightedText = "";
    }, 

    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload; // Set the selected tag
      state.highlightedText = "";
    },

    reset: (state) => {
      for (const key in state) {
        if (state[key] !== initialState[key]) {
          state[key] = initialState[key];
        }
      }
    },

    setUploadingFiles: (state, action) => {
      if (action.payload) {
        state.notificationMessage = "Files are being uploaded...";
      } else {
        state.notificationMessage = "";
      }
      state.uploadingFiles = action.payload;
    },

    setDeletingFiles: (state, action) => {
      if (action.payload) {
        state.notificationMessage = "Files are being deleted...";
      } else {
        state.notificationMessage = "";
      }
      state.deletingFiles = action.payload;
    },
  },
});