import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Card, CardContent, Grid, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { interfaceSlice } from "../store/interfaceSlice";
import CreditToDollarCalculator from "../utils/CreditToDollarCalculator";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default function CreditPage() {
  const dispatch = useDispatch();
  const userCredit = useSelector((state) => state.user.credit);
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order failed."
      );
    }
  }, []);

  useEffect(() => {
    dispatch(interfaceSlice.actions.setRefetchGetCredit());
  }, []);

  const handleSubscribe = async (amount) => {
    if (!user && !accessToken) return;
    let idToken = null;
    if (user) {
      idToken = await user.getIdToken();
    } else {
      idToken = accessToken;
    }

    try {
      const response = await axios.post(
        REACT_APP_BACKEND_URL + "api/auth/create-checkout-session/",
        {
          amount: amount,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.url) {
        window.location.href = response.data.url;
      }

    } catch (error) {
      console.error("Error adding credits to user's account:", error);
    }
  }
  

  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: "1000px",
          height: "100vh",
          overflowY: "auto",
          backgroundColor: "#f0f0f0",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: "30%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "70%",
              height: "30px",
              textAlign: "center",
            }}
          >
            <Typography variant="h3">
              No subscription, No commitment. No time limit. <br/>
              Use as much as you need.
            </Typography>


            <Typography variant="h5" marginTop={3}>
              Your current credit: ${CreditToDollarCalculator(userCredit).toLocaleString()}
            </Typography>

            <Typography variant="h6" marginTop={1}>
              {message ? message : ""}
            </Typography>

          </Box>

        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: "50%",
            width: "100%",
            gap: 4
          }}
        >
          
          <FreeOptionCard/>

          <PaidOptionCard
            amount={12}
            handleSubscribe={handleSubscribe}
          />

          <PaidOptionCard
            amount={60}
            handleSubscribe={handleSubscribe}
          />

        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: "20%",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              marginBottom: 3,
              textAlign: "center",
            }}
            variant="body1"
          >
            * Words: The current credit calculation is based on the number of input words. <br/>
            The output words are worth 4x of the input words. <br/>
            Input image of 1000x1000 pixels is roughly worth 250 input words. <br/>
            These figures are estimates.
          </Typography>

        </Box>
        
      </Box>
  );
}


function FreeOptionCard({}) {

  const isPaidUser = useSelector((state) => state.user.isPaidUser);

  return (
    <Box
      sx={{
        borderRadius: 4,
        border: "2px solid #909090",
        width: "25%",
        height: "80%",
        textAlign: "center",
        boxShadow: 2,
        "&:hover": {
          boxShadow: 5,
        }
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "30%",
          marginTop: "10%",
        }}
      >
        <Typography variant="h4">
          Free
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "70%",
        }}
      >
        <Typography variant="h5">
          100,000 credits ≈ 75,000 words*
        </Typography>


        <Typography variant="h5">
            Limited functionality
        </Typography>

        <Typography variant="body1">
          Max 2 PDFs at a time
        </Typography>

        {!isPaidUser && (
          <Typography
            sx={{
              color: "red",
              marginTop: "10%",
            }}
            variant="body1"
          >
            Your current plan.
          </Typography>
        )}
      </Box>

    </Box>
  );
}




function PaidOptionCard({
  amount,
  handleSubscribe,
}) {

  return (
    <Box
      sx={{
        borderRadius: 4,
        border: "2px solid #909090",
        width: "25%",
        height: "80%",
        textAlign: "center",
        boxShadow: 2,
        "&:hover": {
          boxShadow: 5,
        }
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "30%",
          marginTop: "10%",
        }}
      >
        <Typography variant="h4">
          ${amount}
          {/* <Typography variant="caption" component="span">
          &#8197;+ tax
          </Typography> */}
        </Typography>

        <Typography variant="h6">
          {amount === 60 && "(25% discount)"}
        </Typography>
        
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "70%",
        }}
      >

        {amount === 12 && (
            <>
              <Typography variant="h5">
                  1.5 Million credits ≈ 1.1 Million words*
              </Typography>

              <Typography variant="h5" color="blue">
                ~ 1 article with 20 references
              </Typography>
            </>
          )
        }

        {amount === 60 && (
            <>
              <Typography variant="h5">
                  10 Million credits ≈ 7.5 Million words* <br/>
              </Typography>

              <Typography variant="h5" color="blue">
                ~ 6 articles with 20 references
              </Typography>
            </>
          )
        }
        
        <Typography variant="body1">
          Up to 100 PDFs at a time
        </Typography>


        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: 4,
          }}
          onClick={() => handleSubscribe(amount)}
        >
          Add credits
        </Button>


      </Box>

    </Box>
  );
}